import React, {useContext} from 'react';
import {useParams, useSearchParams} from "react-router-dom";
import Toolbar from "../componts/Toolbar";
import {ContextModal} from "../provider/ModalProvider";

const ModalMedia = () => {
    const {closeModal} = useContext(ContextModal)
    const [getSearchParams, setSearchParams] = useSearchParams()

    return (
        <div
            onClick={closeModal}
            className={'h-screen w-screen bg-black bg-opacity-[90%] max-sm:bg-opacity-[100%]'}>
            <Toolbar
                theme={'DARK'}
                contextType={'FLOW'}
                invisible={true}/>
            <div className={'flex h-full w-full py-[52px]'}>
                <img className={'m-auto max-sm:w-full max-h-full'}
                     src={getSearchParams.get('url')!!}
                     alt={'/media'}/>
            </div>
        </div>
    )
}

export default ModalMedia;