import axios from "axios";
import {getToken} from "../functions";
import {token} from "../provider/AuthProvider";

const $api = axios.create({
    withCredentials: true,
    baseURL: 'http://localhost:3000' ?
        "https://pre-prod.yetap.me" : "https://api.yetap.me"
});
$api.interceptors.request.use((config) => {
    const jwt = localStorage.getItem(token)
    if (jwt) config.headers.Authorization = `Bearer ${jwt}`;
    return config
});
export default $api