import React, {useEffect} from 'react';
import {useParams} from "react-router-dom";
import Toolbar from "../../../componts/Toolbar";
import {ScreenSize} from "../../../const/screen";
import StoreCard from "../../../store/StoreCard";
import SectionEmpty from "../../../sections/SectionEmpty";
import {toFlow} from "../../../functions";
import {observer} from "mobx-react-lite";
import Loader from "../../../componts/Loader";
import ServiceCard from "../../../serivices/ServiceCard";
import ContactEditItem from "../../../items/contacts/ContactEditItem";
import WrapperSection from "../../../wrappers/WrapperSection";
import {
    DragDropContext,
    Draggable,
    DraggableProvided,
    Droppable,
    DroppableProvided,
    DropResult
} from "react-beautiful-dnd";

const ModalEditContacts = observer(() => {
    const {shortname_param} = useParams()
    const shortname = shortname_param!!
    useEffect(() => {
        StoreCard.isContacts() == undefined && ServiceCard.getContacts(shortname)
            .then(res => StoreCard.setContacts(res.data))
    }, [shortname_param])
    const onDragEnd = (result: DropResult) => {
        if (!result.destination) return
        console.log(result.source.index, result.destination.index)
        const updatedItems = StoreCard.getContacts()!!
        const [movedItem] = updatedItems.splice(result.source.index, 1)
        updatedItems.splice(result.destination.index, 0, movedItem)
        StoreCard.setContacts(updatedItems)
    };

    return (
        <>
            <Toolbar
                text={'Контакты'}
                contextType={'FLOW'}
                btnRemove={window.innerWidth <= ScreenSize.md ? {} : null}
                btnAction={{
                    text: 'Добавить',
                    to: {
                        value: toFlow('/edit/contacts/types')
                    }
                }}
            />

            {StoreCard.getContacts() != undefined ?
                StoreCard.isContacts() ? <WrapperSection className={'w-full'}>
                        <DragDropContext onDragEnd={onDragEnd}>
                            <Droppable droppableId="droppable">
                                {(provided: DroppableProvided) => (
                                    <div {...provided.droppableProps} ref={provided.innerRef}>
                                        {StoreCard.getContacts()!!.map((x, index) => (
                                            <Draggable key={x.id} draggableId={String(x.id)} index={index}>
                                                {(provided: DraggableProvided) => (
                                                    <div
                                                        {...provided.draggableProps}
                                                        {...provided.dragHandleProps}
                                                        ref={provided.innerRef}
                                                    >
                                                        <ContactEditItem {...x}/>
                                                    </div>
                                                )}
                                            </Draggable>
                                        ))}
                                        {provided.placeholder}
                                    </div>
                                )}
                            </Droppable>
                        </DragDropContext>
                    </WrapperSection> :
                    <SectionEmpty
                        title={'Вы ещё не добавили контакты'}
                        description={'Расскажите гостям вашей страницы как можно с вами связаться'}
                    /> :
                <Loader/>
            }
        </>
    )

})

export default ModalEditContacts;