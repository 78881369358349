import React, {useContext} from 'react';
import {ContextNavigation} from "../provider/NavigationProvider";

interface IComponent {
    children: React.ReactNode,
    to: string,
    className?: string,
    replace?: boolean,
    onClick?: () => void
}

const LinkFrom: React.FC<IComponent> = ({className, children, to, replace = false, onClick}) => {
    const {goTo} = useContext(ContextNavigation)
    const handleClick = () => {
        goTo(to, replace ? {} : null)
        onClick && onClick()
    }
    return (
        <div className={`${className} cursor-pointer`} onClick={handleClick}>
            {children}
        </div>
    );
};

export default LinkFrom;