import React from 'react';
import CloudImage from "../componts/CloudImage";
import {Link} from "react-router-dom";

interface ICard {
    title: string,
    subtitle: string,
    image: string
}

const FunctionCard: React.FC<ICard> = ({title, subtitle, image}) => {
    return (
        <div className={'w-[403.33px]'}>
            <CloudImage image_name={image} className={'h-[403.33px] aspect-square rounded-[20px]'}/>
            <div className={'flex flex-col mx-[10px] mt-[11px] mb-[22px]'}>
                <span className={'w-fit text-[20.7px] font-bold'}>{title}</span>
                <span className={'w-fit text-[15.8px]'}>{subtitle}</span>
            </div>
        </div>
    )
}
const PageLanding = () => {
    return (
        <div className={'flex flex-col'}>
            <div className={'flex flex-col h-screen bg-landing_dark'}>
                <header>
                    <div className={'mx-[100px] flex flex-row justify-between items-center h-[69px]'}>
                        <span className={'text-white text-[38.7px] font-bold'}>
                            Yetap
                        </span>
                        <div className={'flex flex-row space-x-[14px]'}>
                            <Link to={'/signin'} className={'border border-white hover:text-hover_text hover:bg-hover_button_dark hover:border-hover_button_dark flex items-center p-[14.4px] text-white text-[13.2px] rounded-[10px] h-[33px]'}>
                                Вход
                            </Link>
                            <Link to={'/signup'} className={'border border-main hover:bg-hover_button_main flex items-center p-[14.4px] text-white text-[13.2px] bg-main rounded-[10px] h-[33px]'}>
                                Регистрация
                            </Link>
                        </div>
                    </div>
                </header>
                <div className={'flex justify-start items-end h-full pb-[130px] px-[147px]'}>
                    <div className={'w-fit'}>
                        <h1 className={'text-white font-bold text-[38.7px]'}>
                            Онлайн визитки для бизнеса
                        </h1>
                        <h2 className={'text-white text-[19.2px] mt-[17px]'}>
                            Расширяй и управляй своей сетью контактов,<br/>
                            создавая свое окружение и устанавливая новые связи
                        </h2>
                        <Link to={'/signup'} className={'border border-main w-fit hover:bg-hover_button_main rounded-[10px] flex items-center bg-main text-white mt-[30px] text-[18.4px] font-bold h-[46px] px-[20.1px]'}>
                            Создать визитку
                        </Link>
                    </div>
                </div>
            </div>
            <div className={'pt-[80px] pb-[30px]'}>
                <div className={'flex flex-col h-full'}>
                    <span className={'mx-auto text-[43.5px] font-bold'}>
                        Как это работает?
                    </span>
                    <div className={'px-[85px] flex flex-row justify-between space-x-[30px] mt-[43px] pb-[30px]'}>
                        <FunctionCard
                            title={'1. Создайте профиль'}
                            subtitle={'Создайте привлекательный цифровой профиль,\n' +
                                'демонстрирующий его другим'}
                            image={'card_profile.png'}
                        />
                        <FunctionCard
                            title={'2. Получите карту'}
                            subtitle={'Закажите свою карту Yetap и начните с\n' +
                                'простого процесса активации.'}
                            image={'card_scan.png'}
                        />
                        <FunctionCard
                            title={'3. Собирайте лиды и контакты'}
                            subtitle={'Делитесь своим профилем одним касанием и наблюдайте,\n' +
                                'как растет ваша сеть.'}
                            image={'nfs-card.png'}
                        />
                    </div>
                </div>
            </div>
            <div className={'flex flex-col items-center'}>
                <div className={'flex flex-row h-[700px] w-[1300px] bg-landing_area1 rounded-[47px] justify-between'}>
                    <CloudImage image_name={'card_elements.png'} className={'w-[586px] h-[518px] mt-[116px] ml-[59px]'}/>
                    <div className={'flex flex-col items-start mt-[172px] mr-[65px] w-[571px]'}>
                        <h3 className={'text-[41.8px] font-bold'}>Ваш профессиональный профиль</h3>
                        <span className={'text-[16px] mt-[17px]'}>Визитка, которая станет вашим профессиональным профилем в
интернете и будет с вами в нужный момент, никогда не потеряется и не
закончится, давая вам возможность рассказать о себя и своем
бизнесе, обменявшись контактными данными с потенциальными
клиентами или партнерами с помощью NFC и QR аксессуаров или же с
помощью ссылки на ваш профиль в сообщение</span>
                        <Link to={'/signup'} className={'text-[19.2px] font-bold text-main hover:text-hover_button_main mt-[64px]'}>Начать использовать</Link>
                    </div>
                </div>
                <div className={'flex flex-row h-[700px] w-[1300px] mt-[30px] bg-landing_area2 rounded-[47px] justify-between'}>
                    <div className={'flex flex-col mt-[203px] ml-[65px] w-[547px]'}>
                        <h3 className={'text-[41.8px] font-bold text-white'}>Вы еще пользуетесь бумажными визитками?</h3>
                        <span className={'text-[16px] text-white'}>И это даже несмотря на то, что они имеют свойство заканчиваться,
заставляя иметь на каждом мероприятии целую стопку визиток, при
этом имея ограниченное пространство для указания информации, а
данные, которые вы дали клиенту, со временем устаревают без
возможности редактирования.</span>
                        <Link to={'/signup'} className={'flex hover:bg-hover_button_dark items-center bg-landing_dark mt-[27px] text-[15.5px] h-[38.84px] text-white rounded-[10px] px-[18px] w-fit'}>Получить визитку</Link>
                    </div>
                    <CloudImage image_name={'card_exchange.png'} className={'w-[558px] h-[562px] mr-[94px] mt-[66px]'}/>
                </div>
            </div>
            <a href={'https://fasie.ru/'} className={'m-auto mt-[60px] mb-[30px]'}>
                <div className={'flex flex-col w-[376px] space-y-[30px]'}>
                    <CloudImage image_name={'fasie.png'} className={'w-[376px] h-[211px]'}/>
                    <span className={'font-bold text-[15.5px] text-center'}>
                        Проект поддержан ФГБУ «Фонд содействия развитию малых форм предприятий в научно-технической сфере» по программе «СТУДЕНЧЕСКИЙ СТАРТАП»
                    </span>
                </div>
            </a>
        </div>
    )
}

export default PageLanding;