import {makeAutoObservable} from "mobx";
import {IGetContact} from "../interface/IContact";
import {IGetSkill, IResume} from "../interface/IResume";
import {IGetCard, IGetCardMain} from "../interface/ICard";
import {IGetFile} from "../interface/IFile";
import {IGetMedia} from "../interface/IMedia";

interface IStore extends IGetCard {
    contacts?: IGetContact[],
    resume?: IResume
    medias?: IGetFile[]
    files?: IGetFile[]
}

class StoreCard {
    private card: IStore | null = null

    constructor() {
        makeAutoObservable(this)
    }

    isInit = () => {
        return !!this.card
    }
    init = (card: IGetCard) => {
        this.card = card
    }
    isExperiences = () => {
        if (this.card?.resume?.experiences == undefined) return undefined
        return !!this.card.resume.experiences.length
    }
    isEducations = () => {
        if (this.card?.resume?.educations == undefined) return undefined
        return !!this.card.resume.educations.length
    }
    isSkills = () => {
        if (this.card?.resume?.skills == undefined) return undefined
        return !!this.card.resume.skills.length
    }
    getContacts = () => {
        return this.card?.contacts
    }
    setContacts = (contacts: IGetContact[]) => {
        if (this.card) this.card.contacts = contacts
    }
    isContacts = () => {
        if (this.card?.contacts == undefined) return undefined
        return !!this.card.contacts.length
    }
    isContactsInit = () => {
        return this.card?.contacts != undefined
    }
    appendContact = (value: IGetContact) => {
        this.card?.contacts?.push(value)
    }
    isAvatarUrl = () => {
        return !!this.card?.avatarUrl
    }
    isBackgroundUrl = () => {
        return !!this.card?.backgroundUrl
    }
    appendSkill = (value: IGetSkill) => {
        this.card?.resume?.skills?.push(value)
    }
    appendMedia = (value: IGetFile) => {
        this.card?.medias?.push(value)
    }
    appendFile = (value: IGetFile) => {
        this.card?.files?.push(value)
    }

    removeSkill = (id: number) => {
        if (this.card?.resume?.skills) this.card.resume.skills = this.card.resume.skills?.filter(x => x.id != id)
    }
    setMain = (main: IGetCardMain) => {
        if (main == null) this.card = main
        else this.card = {
            ...this.card!!,
            ...main
        }
    }
    setAvatar = (url: string) => {
        this.card!!.avatarUrl = url
    }
    setBackground = (url: string) => {
        this.card!!.backgroundUrl = url
    }
    getId = () => {
        return this.card?.id ? this.card.id : null
    }
    getAccountId = () => {
        return this.card?.accountId ? this.card.accountId : null
    }
    getName = () => {
        return this.card?.name ? this.card.name : ''
    }
    getTitle = () => {
        return this.card?.title ? this.card.title : null
    }
    getDescription = () => {
        return this.card?.description ? this.card.description : null
    }
    getAvatarUrl = () => {
        return this.card?.avatarUrl ? this.card.avatarUrl : null
    }
    getBackgroundUrl = () => {
        return this.card?.backgroundUrl ? this.card.backgroundUrl : null
    }
    getCity = () => {
        const city = this.card?.details.find(x => x.type == 'CITY')?.value
        return city ? city : null
    }
    getCompanyName = () => {
        const companyName = this.card?.details.find(x => x.type == 'COMPANY')?.value
        return companyName ? companyName : null
    }
    getTabs = () => {
        return this.card?.tabs
    }
    getDetails = () => {
        return this.card?.details.length ? this.card.details : []
    }
    isResume = () => {
        return !!this.card?.resume
    }

    isResumeInit = () => {
        return this.card?.resume != undefined
    }
    getResume = () => {
        return this.card?.resume
    }
    getExperiences = () => {
        return this.card?.resume?.experiences
    }
    getEducations = () => {
        return this.card?.resume?.educations
    }
    getSkills = () => {
        return this.card?.resume?.skills
    }

    getSkillById = (id: number) => {
        return this.card?.resume?.skills?.find(x => x.id = id)
    }
    getSkillByValue = (value: string) => {
        return this.card?.resume?.skills.find(x => x.skill === value)
    }
    getContactById = (id: number) => {
        return this.card?.contacts?.find(x => x.id = id)
    }
    replaceContact = (value: IGetContact) => {
        if (this.card?.contacts) {
            const index = this.card.contacts.findIndex(item => item.id === value.id);
            this.card.contacts[index] = value
        }
    }
    setMedias = (values: IGetFile[]) => {
        this.card!!.medias = values
    }
    getMedias = () => {
        return this.card?.medias
    }
    isMedias = () => {
        const medias = this.getMedias();
        return medias ? medias.length > 0 : false;
    }
    isMediasInit = () => {
        const medias = this.getMedias();
        return medias != undefined
    }
    isFiles = () => {
        return this.card?.files ? this.card?.files?.length > 0 : false
    }
    setFiles = (values: IGetFile[]) => {
        this.card!!.files = values
    }

    getFiles = () => {
        return this.card?.files
    }
    setResume = (value: IResume) => {
        this.card!!.resume = value
    }
    clear = () => {
        this.card = null
    }
}

export default new StoreCard();