import $api from "../api/interceptor";
import {IGetContactType} from "../interface/IContact";

export default class ServiceContacts {
    public static getContactTypes() {
        return $api.get<IGetContactType[]>('/contacts/types')
    }

    public static getContactType(type: string) {
        return $api.get<IGetContactType>(`/contacts/types/${type}`)
    }


}