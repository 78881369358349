import React, {useContext, useEffect, useRef, useState} from 'react';
import Toolbar from "../componts/Toolbar";
import StoreNav, {NavTypes} from "../store/StoreNavMenu";
import Button from "../componts/Button";
import ListCardDetails from "../list/ListCardDetails";
import Avatar from "../componts/Avatar";
import Background from "../componts/Background";
import {Outlet, useParams} from "react-router-dom";
import Tabs from "../navigation/tabs/Tabs";
import ServiceCard from "../serivices/ServiceCard";
import {IGetCard} from "../interface/ICard";
import SectionContacts from "../sections/SectionContacts";
import SectionResume from "../sections/SectionResume";
import SectionMedias from "../sections/SectionMedias";
import SectionFiles from "../sections/SectionFiles";
import {TabTypes} from "../interface/ITab";
import {downloadFile, toFlow} from "../functions";
import StoreCardEdit from "../store/StoreCard";
import {observer} from "mobx-react-lite";
import {ContextAuth} from "../provider/AuthProvider";
import MenuCard from "../menus/MenuCard";
import {offline} from "../App";
import axios from "axios";
import ServiceAuthorization from "../serivices/ServiceAuthorization";

const card: IGetCard = {
    id: 7,
    name: "Даниил Обухов",
    title: "Руководитель проекта",
    avatarUrl: 'https://sun6-22.userapi.com/s/v1/ig2/-G9HdyaRzOixbe9zakNwgv8PZT88yj40oNYhPhA0Ed3Deve3Q9ChqpRMyw303dsbR0tR91Jj2lTQd0XoMcv4N7Nm.jpg?size=400x400&quality=95&crop=664,344,1228,1228&ava=1',
    mainShortname: "uixyphdb",
    type: "PERSON",
    custom: false,
    description: "Middle бэкенд разработчик. Участник трех стартап проектов. Победитель и участник хакатонов LeaderCup, Росмолодежи и др. Прошел акселерационную программу TechnoProject в области технологического предпринимательства. Обучается в Санкт-Петербургском политехническом университет Петра Великого по направлению системный анализ и управление.",
    albumId: 8,
    backgroundUrl: 'https://sun9-47.userapi.com/impg/U1pYocmZv1nFZrS5iQCqHjIbeoQNz-gvoGv2UA/useytArUdP0.jpg?size=604x453&quality=96&sign=c35226f3cf51fa9284c41e1d5c3eca5b&c_uniq_tag=RO4JGfXSOJE7tarS7X_c33pFOEn3DU-JVViJydVIpT4&type=album',
    cardName: "Yetap",
    accountId: 7,
    tabs: [
        {
            id: 0,
            writing: "Контакты",
            order: 0,
            type: TabTypes.CONTACTS
        },
        {
            id: 1,
            writing: "Резюме",
            order: 1,
            type: TabTypes.RESUME
        },
        {
            id: 2,
            writing: "Медиа",
            order: 2,
            type: TabTypes.MEDIAS
        },
        {
            id: 3,
            writing: "Файлы",
            order: 3,
            type: TabTypes.FILES
        }
    ],
    details: [
        {
            "icon": "https://s3.timeweb.com/2cc1de15-bc1f377d-9e5a-448f-8a1d-f117b93916d2/img_place.svg",
            "type": "CITY",
            "value": "Санкт-Петербург"
        },
        {
            "icon": "https://s3.timeweb.com/2cc1de15-bc1f377d-9e5a-448f-8a1d-f117b93916d2/img_work.svg",
            "type": "COMPANY",
            "value": "Yetap"
        }
    ],
}
const my = [
    {
        id: 1,
        order: 1,
        main: false,
        writing: 'Настройки',
        type: 'setting'
    },
    {
        id: 2,
        order: 2,
        main: false,
        writing: 'QR-код',
        type: 'qr'
    },
]
const guest_not_authorized = [
    {
        id: 1,
        order: 4,
        main: true,
        writing: 'Скачть',
        type: 'download'
    },
    {
        id: 2,
        order: 2,
        main: false,
        writing: 'QR-код',
        type: 'qr'
    },
]
const guest_authorized_not_saved = [
    {
        id: 3,
        order: 1,
        main: true,
        writing: 'Сохранить',
        type: 'save'
    },
    {
        id: 2,
        order: 2,
        main: false,
        writing: 'QR-код',
        type: 'qr'
    },
]
const guest_authorized_saved = [
    {
        id: 1,
        order: 1,
        main: false,
        writing: 'Настройки',
        type: 'save'
    },
    {
        id: 2,
        order: 2,
        main: false,
        writing: 'QR-код',
        type: 'qr'
    },
    {
        id: 5,
        order: 2,
        main: false,
        writing: 'Удалить связь',
        type: 'delete'
    },
]

const PageCard = observer(() => {
    const {shortname_param, type_param} = useParams()
    const shortname = shortname_param
    const {isAuth, accountId} = useContext(ContextAuth)
    const [tab, setTab] = useState<string>()
    const initCard = (card: IGetCard) => {
        document.title = card.name
        card.accountId == accountId ? StoreNav.setNav(NavTypes.myProfile) : StoreNav.clear()
        StoreCardEdit.init(card)
    }
    useEffect(() => {
        if (offline) {
            initCard(card)
        } else
            ServiceCard.getCard(shortname!!)
                .then(res => {
                    initCard(res.data)
                })
        return () => {
            StoreCardEdit.clear()
        }

    }, [shortname_param])
    return (
        StoreCardEdit.isInit() ? <div>
            <Outlet/>
            <Toolbar
                text={isAuth ? StoreCardEdit.getName()!! : 'Yetap'}
                btnRemove={null}
                btnAction={!isAuth ? {
                    text: 'Создать визитку',
                    to: {
                        value: toFlow('/signup')
                    }
                } : null}
            />
            <div>
                <Background chooseType={'CARD'}/>
                <div className={'px-[16px] pt-[12px] pb-[16px]'}>
                    <div className={'flex flex-row flex-wrap justify-between'}>
                        <div className={'flex w-1/4 relative h-[68px] max-sm:h-[48px]'}>
                            <div className={'flex flex-row items-end absolute h-full bottom-[12px] w-full'}>
                                <Avatar chooseType={'CARD'}/>
                            </div>
                        </div>
                        <div className={'flex flex-row flex-wrap gap-[8px] items-start'}>
                            {isAuth && accountId === StoreCardEdit.getAccountId()!! ? <>
                                <Button content={'QR-код'} to={{
                                    value: toFlow('/qr')
                                }}/>
                                <Button content={'Настройки'} to={{
                                    value: toFlow('/edit')
                                }}/>
                            </> : <Button content={isAuth ? 'Сохранить' : 'Скачать'} format_type={'sm_main'}
                                          onClick={() => downloadFile(`https://pre-prod.yetap.me/relations/save?id=${StoreCardEdit.getId()!!}`)}/>}
                        </div>
                    </div>
                    <div className={'flex flex-col'}>
                        <div className={'flex flex-col pt-[4px] pb-[16px]'}>
                            <span className={'text-[20px] font-extrabold'}>{StoreCardEdit.getName()}</span>
                            {StoreCardEdit.getTitle() &&
                                <span className={'text-[15px] text-secondary'}>{StoreCardEdit.getTitle()!!}</span>}
                        </div>
                        {StoreCardEdit.getDetails() && <ListCardDetails list={StoreCardEdit.getDetails()!!}/>}
                        {StoreCardEdit.getDescription() && <div className={'pb-[4px]'}>
                            <span className={'text-[15px]'}>{StoreCardEdit.getDescription()!!}</span>
                        </div>}
                    </div>
                </div>
                {StoreCardEdit.getTabs()!!.length > 0 && <div className={'min-h-[400px]'}>
                    <Tabs getTab={tab} setTab={setTab} className={'top-[52.8px]'} tabs={StoreCardEdit.getTabs()!!}/>
                    {tab === TabTypes.CONTACTS && <SectionContacts/>}
                    {tab === TabTypes.RESUME && <SectionResume/>}
                    {tab === TabTypes.MEDIAS && <SectionMedias/>}
                    {tab === TabTypes.FILES && <SectionFiles/>}
                </div>}
            </div>
        </div> : <></>
    );
})

export default PageCard;