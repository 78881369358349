import React from 'react';
import WrapperModal from "../wrappers/WrapperModal";

const ModalLead = () => {
    return (
        <WrapperModal
            toolbarText={'Yetap'}
            btnAction={{
                text: 'Обменяться'
            }}>
            <div className={'px-[80px] max-sm:px-[32px]'}>
                <div className={'py-[20px]'}>
                    <span className={'font-bold text-[31px] max-sm:text-[26px]'}>Обменяйтесь контактами</span>
                </div>
                <div>

                </div>
            </div>
        </WrapperModal>
    );
};

export default ModalLead;