import React, {useContext, useEffect, useState} from 'react';
import {IGetContactType} from "../../interface/IContact";
import ServiceContacts from "../../serivices/ServiceContacts";
import Toolbar from "../../componts/Toolbar";
import ContactTypeItem from "../../items/contacts/ContactTypeItem";
import WrapperSection from "../../wrappers/WrapperSection";
import Loader from "../../componts/Loader";
import {ContextNavigation} from "../../provider/NavigationProvider";
import {ScreenSize} from "../../const/screen";

const ModalContactTypes = () => {
    const {isPrevFlow} = useContext(ContextNavigation)
    const [getContactTypes, setContactTypes] = useState<IGetContactType[]>()
    useEffect(() => {
        ServiceContacts.getContactTypes()
            .then(res => {
                const contact_types = res.data
                setContactTypes(contact_types)
            })
    }, []);
    return (
        <>
            <Toolbar
                text={'Тип контакта'}
                contextType={'FLOW'}
                btnRemove={(isPrevFlow() || window.innerWidth <= ScreenSize.md) ? {} : null}
            />
            {getContactTypes != undefined ? <WrapperSection>
                <div className={'grid grid-cols-5 max-sm:grid-cols-4 px-[16px] gap-x-[12px]'}>
                    {getContactTypes?.map(x => <ContactTypeItem {...x}/>)}
                </div>
            </WrapperSection> : <Loader/>}
        </>
    );
};

export default ModalContactTypes;