import React from 'react';

interface ICloudImage {
    className:string,
    image_name:string
}

const CloudImage: React.FC<ICloudImage> = ({className,image_name}) => {
    return (
        <img src={`https://s3.timeweb.cloud/87249837-6c014d16-2ff8-45e7-934e-7b36dd7655a2/${image_name}`} className={className} alt={''}/>
    )
}
export default CloudImage