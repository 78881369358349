import React, {ChangeEvent, useEffect, useRef} from 'react';
import FileItem from "../../items/FileItem";
import Toolbar from "../../componts/Toolbar";
import {ScreenSize} from "../../const/screen";
import ServiceCard from "../../serivices/ServiceCard";
import {useParams} from "react-router-dom";
import StoreCardEdit from "../../store/StoreCard";
import SectionEmpty from "../../sections/SectionEmpty";
import {toFlow} from "../../functions";
import Loader from "../../componts/Loader";
import {observer} from "mobx-react-lite";
import WrapperSection from "../../wrappers/WrapperSection";

const ModalEditFiles = observer(() => {
    const {shortname_param} = useParams()
    const shortname = shortname_param!!
    const fileInputRef = useRef<HTMLInputElement>(null)
    useEffect(() => {
        StoreCardEdit.getFiles() == undefined && ServiceCard.getFiles(shortname!!)
            .then(res => {
                StoreCardEdit.setFiles(res.data)
            })
    }, [shortname_param]);
    const handleClick = () => {
        if (fileInputRef.current) fileInputRef.current.click()
    }
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]
        if (file) ServiceCard.postFile(shortname!!, file)
            .then(res => StoreCardEdit.appendFile(res.data))
    }
    return (
        <>
            <input className={'hidden'}
                   type="file"
                   ref={fileInputRef}
                   onChange={handleFileChange}
            />
            <Toolbar
                text={'Файлы'}
                contextType={'FLOW'}
                btnRemove={window.innerWidth <= ScreenSize.md ? {} : null}
                btnAction={{
                    text: 'Добавить',
                    onClick: handleClick
                }}/>
            {StoreCardEdit.getFiles() != undefined ?
                StoreCardEdit.isFiles() ? <WrapperSection>
                        {StoreCardEdit.getFiles()!!.map(x => <FileItem key={x.id} {...x}/>)}
                    </WrapperSection> :
                    <SectionEmpty
                        title={'Вы ещё не добавили файлы'}
                        description={'Наполните профиль необходимым контентом для гостей вашей страницы'}
                    /> :
                <Loader/>}
        </>
    )
})

export default ModalEditFiles;