import React, {useEffect} from 'react';
import ContainerDull from "../../container/ContainerDull";
import {ITab} from "../../interface/ITab";
import Tab from "./Tab";


interface ITabs {
    tabs: ITab[],
    className?: string,
    getTab?: string,
    setTab: (type: string) => void
}

const Tabs: React.FC<ITabs> = ({tabs, getTab, setTab, className}) => {
    useEffect(() => {
        setTab(tabs[0].type)
    }, []);
    return (
        tabs.length > 1 ? <ContainerDull className={`${className ? className : 'top-0'} z-tabs border-b`}>
            {tabs.map(x => <Tab key={x.id} active={getTab == x.type} onChoose={() => setTab(x.type)} {...x}/>)}
        </ContainerDull> : <></>
    );
};

export default Tabs;