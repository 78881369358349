import React, {useEffect, useState} from 'react';
import WrapperImage from "../wrappers/WrapperImage";
import Svg from "../media/Svg";
import Button from "../componts/Button";
import {TextField} from "@mui/material";
import WrapperModal from "../wrappers/WrapperModal";
import {useParams} from "react-router-dom";
import ServiceCard from "../serivices/ServiceCard";
import {IGetQr} from "../interface/IQr";

const ModalQr = () => {
    const {shortname_param} = useParams()
    const shortname = shortname_param
    const [isCopied, setIsCopied] = useState(false);
    const [getQr, setQr] = useState<IGetQr>()
    useEffect(() => {
        ServiceCard.getQr(shortname!!)
            .then(res => setQr(res.data))
    }, [])
    return (
        <WrapperModal toolbarText={'QR-код'}>
            {getQr && <div className={'flex flex-col px-[80px] max-sm:px-[16px] w-full h-full'}>
                <div className={'flex-1 flex py-[12px]'}>
                    <div className={'m-auto max-w-[300px] w-full bg-empty rounded-[16px] overflow-hidden'}>
                        <WrapperImage
                            url={`https://${getQr.pictureUrl}`}
                            pb={'pb-[100%]'}/>
                    </div>
                </div>
                <div className={'flex pt-[4px] pb-[12px]'}>
                    <div
                        className={'flex flex-row mx-auto space-x-[8px] items-center max-w-[300px] w-full text-center'}>
                        <Svg className={'h-[20px]'} name={'scan'}/>
                        <span>Наведите камеру на QR-код, чтобы поделиться профилем</span>
                    </div>
                </div>
                <div className={'pt-[12px] pb-[14px]'}>
                    <TextField
                        id="link"
                        label="Ссылка"
                        fullWidth
                        disabled
                        value={`yetap.me/${getQr.mainShortname}`}
                    />
                </div>
                <div className={'py-[24px]'}>
                    <Button format_type={'lg_main_full'} content={'Поделиться'}/>
                </div>
            </div>}
        </WrapperModal>
    )

};

export default ModalQr;