import React, {useContext} from 'react';
import ContainerDull from "../container/ContainerDull";
import {NavTypes} from "../store/StoreNavMenu";
import NavMobile, {INavMobile} from "../navigation/navs/NavMobile";
import {ContextAuth} from "../provider/AuthProvider";

const MenuMobile = () => {
    const {mainShortname} = useContext(ContextAuth)

    const menus: INavMobile[] = [
        {
            id: NavTypes.relations,
            icon: 'relations',
            value: '/relations',
        }, {
            id: NavTypes.myProfile,
            icon: 'my-profile',
            value: `/${mainShortname}`,
        },
    ]
    return (
        <ContainerDull className={'z-bottom border-t bottom-0 sm:hidden'}>
            {menus.map(x => <NavMobile key={x.id} {...x}/>)}
        </ContainerDull>
    )
};

export default MenuMobile;