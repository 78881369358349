import React from 'react';
import Svg from "../../media/Svg";
import {Link} from "react-router-dom";
import LinkFrom from "../../custome/LinkFrom";
import {toFlow} from "../../functions";
import {ISetting} from "../../interface/ISetting";

interface IComponent extends ISetting {
    active?: boolean
}

const SettingItem: React.FC<IComponent> = ({title, active, to, icon, description}) => {
    return <LinkFrom className={`px-[16px] py-[12px] ${!description && 'max-md:py-[16px]'} hover:bg-hover_button border-main`} to={to}>
        <div className={'flex flex-row items-center justify-between pr-[12px]'}>
            <div className={'flex flex-row items-center'}>
                {icon && <div className={'pr-[16px]'}>
                    <div className={'flex w-[48px] aspect-square'}>
                        <div className={'flex m-auto'}>
                            <Svg name={icon}/>
                        </div>
                    </div>
                </div>}
                <div className={'flex flex-col items-start'}>
                    {description ? <>
                        <p>{title}</p>
                        <p className={'text-[13px] text-secondary'}>{description}</p>
                    </> : <p className={'max-md:text-[20px]'}>{title}</p>}
                </div>
            </div>
            <Svg name={'next'}/>
        </div>
    </LinkFrom>
}

export default SettingItem;