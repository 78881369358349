import React, {useContext, useEffect, useRef, useState} from 'react';
import {useParams} from "react-router-dom";
import StoreCardEdit from "../../../store/StoreCard";
import ServiceCard from "../../../serivices/ServiceCard";
import Toolbar from "../../../componts/Toolbar";
import SectionEmpty from "../../../sections/SectionEmpty";
import Loader from "../../../componts/Loader";
import SkillItem from "../../../items/SkillItem";
import WrapperComponent from "../../../wrappers/WrapperComponent";
import {TextField, ThemeProvider} from "@mui/material";
import WrapperForm from "../../../wrappers/WrapperForm";
import {observer} from "mobx-react-lite";
import {textFieldTheme} from "../../../theme";
import {SubmitHandler, useForm} from "react-hook-form";
import {IPutContact} from "../../../interface/IContact";
import StoreCard from "../../../store/StoreCard";
import {toFlow} from "../../../functions";
import {IGetSkill} from "../../../interface/IResume";
import {ScreenSize} from "../../../const/screen";
import {ContextNavigation} from "../../../provider/NavigationProvider";

interface IForm {
    value: string
}

const ModalEditSkills = observer(() => {
    const {shortname_param} = useParams()
    const shortname = shortname_param!!
    const {isPrevFlow} = useContext(ContextNavigation)
    const [getInput, setInput] = useState<string>('')
    const [getError, setError] = useState<string>('')
    const {
        register, handleSubmit, trigger, reset,
        getValues, setValue,
        formState: {errors}
    } = useForm<IForm>()
    useEffect(() => {
        StoreCard.isSkills() == undefined && ServiceCard.getResume(shortname)
            .then(res => StoreCard.setResume(res.data))
    }, []);
    const handleEnter = (e: React.KeyboardEvent<HTMLInputElement>) => {
        if (e.key === 'Enter') {
            if (!getInput) setError('Навык не может быть пустой строкой')
            if (getInput.length >= 80) setError('Длина навыка не должна привышать 80 символов')
            else if (StoreCard.getSkillByValue(getInput)) setError('Навык долже быть уникальынм')
            else {
                ServiceCard.postSkill(shortname, getInput)
                    .then(res => StoreCard.appendSkill(res.data))
                setInput('')
            }
        }
    }
    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setError('')
        setInput(e.target.value)
    }
    return (
        <>
            <Toolbar
                text={'Навыки'}
                contextType={'FLOW'}
                btnRemove={(isPrevFlow() || window.innerWidth <= ScreenSize.md) ? {} : null}
            />
            {StoreCardEdit.isSkills() != undefined ?
                <>
                    <div className={'px-[16px]'}>
                        <ThemeProvider theme={textFieldTheme}>
                            <TextField
                                value={getInput}
                                onChange={handleChange}
                                onKeyPress={handleEnter}
                                label={'Навык'}
                                error={!!getError}
                                helperText={getError}
                            />
                        </ThemeProvider>
                    </div>
                    {StoreCardEdit.isSkills() ?
                        <WrapperComponent className={'flex flex-row flex-wrap gap-[8px] px-[16px]'}>
                            {StoreCardEdit.getSkills()!!.map(x => <SkillItem remove={true} {...x}/>)}
                        </WrapperComponent> :
                        <SectionEmpty
                            title={'Вы ещё не добавили навыки'}
                            description={'Расскажите гостям вашей страницы, чем вы можете быть им полезны'}
                        />}
                </> :
                <Loader/>}
        </>
    )
})

export default ModalEditSkills;