import React, {useContext, useEffect} from 'react';
import {Outlet} from "react-router-dom";
import {ContextModal} from "../provider/ModalProvider";

interface IWrapper {
    children?: React.ReactNode
}

const WrapperFlow: React.FC<IWrapper> = ({children}) => {
    const {closeModal} = useContext(ContextModal)
    const handleModal = (e: React.MouseEvent) => {
        e.stopPropagation()
    }
    useEffect(() => {
        document.body.style.overflow = 'hidden'
        return () => {
            document.body.style.overflow = 'auto'
        };
    }, []);
    return (
        <div onClick={closeModal}
             className={'fixed top-0 left-0 flex h-screen w-screen bg-black bg-opacity-[40%] z-flow-modal'}>
            <div onClick={handleModal}
                 className={'m-auto max-sm:h-full max-sm:w-full z-modal'}>
                {children ? children : <Outlet/>}
            </div>
        </div>
    );
};

export default WrapperFlow;