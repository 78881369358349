import React from 'react';

interface IWrapper {
    className?:string,
    children:React.ReactNode
}

const WrapperSection:React.FC<IWrapper> = ({className,children}) => {
    return (
        <div className={`${className} w-full pt-[8px] pb-[2px]`}>
            {children}
        </div>
    );
};

export default WrapperSection;