import React from 'react';
import WrapperComponent from "../wrappers/WrapperComponent";

interface IComponent {
    text: string
}

const Title: React.FC<IComponent> = ({text}) => {
    return (
        <WrapperComponent className={'px-[16px]'}>
            <span className={'text-[17px] font-bold'}>{text}</span>
        </WrapperComponent>
    );
};

export default Title;