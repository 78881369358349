import React from 'react';
import WrapperFlow from "../wrappers/WrapperFlow";
import ModalSignIn from "../modal/authorization/ModalSignIn";
import ModalSignUp from "../modal/authorization/ModalSignUp";

const PageSignUp = () => {
    return (
        <WrapperFlow>
            <ModalSignUp/>
        </WrapperFlow>
    );
};

export default PageSignUp;