import React, {useEffect} from 'react';
import Toolbar from "../componts/Toolbar";
import StoreMyProfile, {NavTypes} from "../store/StoreNavMenu";
import StoreNav from "../store/StoreNavMenu";
import {Outlet} from "react-router-dom";
import RelationItem from "../items/RelationItem";
import WrapperSection from "../wrappers/WrapperSection";

const PageRelations = () => {
    useEffect(() => {
        StoreNav.setNav(NavTypes.relations)
        document.title='Связи'
    })
    return (
        <>
            <Toolbar
                btnRemove={null}
                text={'Связи'}/>
            <WrapperSection>
                <RelationItem/>
                <RelationItem/>
                <RelationItem/>
                <RelationItem/>
            </WrapperSection>
        </>
    );
};

export default PageRelations;