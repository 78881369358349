import React from 'react';

const Loader = () => {
    return (
        <div className={'flex w-full h-full'}>
            <span className={'m-auto'}>Loading...</span>
        </div>
    );
};

export default Loader;