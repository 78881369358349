import React, {useContext} from 'react';
import {BrowserRouter, Navigate, Route, Routes} from "react-router-dom";
import PageCard from "./pages/PageCard";
import WrapperMain from "./wrappers/WrapperMain";
import PageRelations from "./pages/PageRelations";
import AuthProvider, {ContextAuth} from "./provider/AuthProvider";
import WrapperFlow from "./wrappers/WrapperFlow";
import ModalQr from "./modal/ModalQr";
import ModalSettings from "./modal/edit_card/ModalSettings";
import ModalEditMain from "./modal/edit_card/ModalEditMain";
import ModalEditContacts from "./modal/edit_card/contacts/ModalEditContacts";
import ModalContactTypes from "./modal/types/ModalContactTypes";
import ModalCreateContact from "./modal/edit_card/contacts/ModalCreateContact";
import ModalResume from "./modal/edit_card/resume/ModalResume";
import ModalEditMedias from "./modal/edit_card/ModalEditMedias";
import ModalEditFiles from "./modal/edit_card/ModalEditFiles";
import ModalSignIn from "./modal/authorization/ModalSignIn";
import ModalSignUp from "./modal/authorization/ModalSignUp";
import ModalProvider from "./provider/ModalProvider";
import ModalEducations from "./modal/edit_card/resume/ModalEducations";
import ModalExperiences from "./modal/edit_card/resume/ModalExperiences";
import ModalEditSkills from "./modal/edit_card/resume/ModalEditSkills";
import ModalLead from "./modal/ModalLead";
import PageSignIn from "./pages/PageSignIn";
import PageSignUp from "./pages/PageSignUp";
import ModalEducation from "./modal/edit_card/resume/ModalEducation";
import ModalExperience from "./modal/edit_card/resume/ModalExperience";
import ModalMedia from "./modal/ModalMedia";
import NavigationProvider from "./provider/NavigationProvider";
import ModalEditContact from "./modal/edit_card/contacts/ModalEditContact";
import StoreCard from "./store/StoreCard";
import {observer} from "mobx-react-lite";
import PageLanding from "./pages/PageLanding";

export const offline = true
const My = () => {
    const {mainShortname} = useContext(ContextAuth)
    return <Navigate to={`/${mainShortname}`}/>
}

interface IRout {
    children: React.ReactNode
}

const Authorize: React.FC<IRout> = ({children}) => {
    const {isAuth} = useContext(ContextAuth)
    return <>
        {isAuth && children}
    </>
}
const NotAuthorize: React.FC<IRout> = ({children}) => {
    const {isAuth} = useContext(ContextAuth)
    return <>
        {!isAuth && children}
    </>
}

const App = observer(() => {
    return (
        <BrowserRouter>
            <NavigationProvider>
                <AuthProvider>
                    <ModalProvider>
                        <Routes>
                            <Route path="/" element={<PageLanding/>}/>
                            <Route path="/signin" element={<PageSignIn/>}/>
                            <Route path="/signup" element={<PageSignUp/>}/>
                            <Route element={<WrapperMain/>}>
                                <Route path="/relations" element={<PageRelations/>}/>
                                <Route path="/" element={<My/>}/>
                                <Route path="/my" element={<My/>}/>
                                <Route path="/:shortname_param" element={<PageCard/>}/>
                                <Route path="/:shortname_param" element={<PageCard/>}>
                                    <Route path={'flow'} element={<WrapperFlow/>}>
                                        <Route path={'signin'} element={<ModalSignIn/>}/>
                                        <Route path={'signup'} element={<ModalSignUp/>}/>
                                        <Route path={'medias'} element={<ModalMedia/>}/>
                                        <Route path={'lead'} element={<ModalLead/>}/>
                                        <Route path={'qr'} element={<ModalQr/>}/>
                                        <Route path="edit" element={<ModalSettings/>}>
                                            <Route path="" element={<ModalEditMain/>}/>
                                            <Route path={"contacts"}>
                                                <Route path=""
                                                       element={StoreCard.isContacts() == true ? <ModalEditContacts/> :
                                                           <Navigate to={'types'}/>}/>
                                                <Route path=":id_param" element={<ModalEditContact/>}/>
                                                <Route path={'types'}>
                                                    <Route path="" element={<ModalContactTypes/>}/>
                                                    <Route path=":type_param" element={<ModalCreateContact/>}/>
                                                </Route>
                                            </Route>
                                            <Route path={'resume'}>
                                                <Route path="" element={<ModalResume/>}/>
                                                <Route path="educations"
                                                       element={StoreCard.isEducations() == true ? <ModalEducations/> :
                                                           <Navigate to={'education'}/>}/>
                                                <Route path="education" element={<ModalEducation/>}/>
                                                <Route path="experiences" element={StoreCard.isExperiences() == true ?
                                                    <ModalExperiences/> : <Navigate to={'experience'}/>}/>
                                                <Route path="experience" element={<ModalExperience/>}/>
                                                <Route path="skills" element={<ModalEditSkills/>}/>
                                            </Route>
                                            <Route path="files" element={<ModalEditFiles/>}/>
                                            <Route path="medias" element={<ModalEditMedias/>}/>
                                        </Route>
                                    </Route>
                                </Route>
                            </Route>
                        </Routes>
                    </ModalProvider>
                </AuthProvider>
            </NavigationProvider>
        </BrowserRouter>
    )
})

export default App;
