import React from 'react';
import Svg from "../../media/Svg";
import WrapperImage from "../../wrappers/WrapperImage";
import WrapperComponent from "../../wrappers/WrapperComponent";
import {Link} from "react-router-dom";
import {IGetContact} from "../../interface/IContact";
import {toFlow} from "../../functions";
import LinkFrom from "../../custome/LinkFrom";

interface IItem extends IGetContact {
}

const ContactEditItem: React.FC<IItem> = ({id,title, description, logoUrl}) => {
    return (
        <LinkFrom to={toFlow(`/edit/contacts/${id}`)} className={'bg-white'}>
            <WrapperComponent className={'w-full'}>
                <div className={'flex flex-row items-center w-full'}>
                    <Svg name={'swap'} className={'ml-[16px] w-[16px] h-[24px]'}/>
                    <div className={'ml-[12px] contact_logo'}>
                        <WrapperImage className={'rounded-[20px]'} url={logoUrl}/>
                    </div>
                    <div className={'flex flex-col items-start px-[12px] flex-1'}>
                        <p>{title}</p>
                        <p className={'text-secondary'}>{description}</p>
                    </div>
                    <Svg name={'next'} className={'mr-[28px] w-[16px] h-[24px]'}/>
                </div>
            </WrapperComponent>
        </LinkFrom>
    )
}

export default ContactEditItem;