import React from "react";
import Button from "../componts/Button";
import {useSearchParams} from "react-router-dom";
import {IAction} from "../interface/IAction";

export interface ISectionEmpty {
    title: string,
    description: string,
    btnAction?: IAction,
}

const SectionEmpty: React.FC<ISectionEmpty> = ({title, description, btnAction}) => {
    const [getSearchParams, setSearchParams] = useSearchParams()
    return (
        <div className={'flex w-full h-full py-[32px]'}>
            <div className={'m-auto w-[310px]'}>
                <div className={'pb-[8px]'}>
                    <p className={'font-extrabold text-[31px] leading-[32px]'}>{title}</p>
                </div>
                <div className={'pb-[28px]'}>
                    <p className={'text-[15px] text-secondary leading-[20px]'}>{description}</p>
                </div>
                {btnAction &&
                    <Button format_type={'lg_main'} to={btnAction.to} onClick={btnAction.onClick && btnAction.onClick}
                            content_type={'text'} content={btnAction.text}/>}
            </div>
        </div>
    )
};

export default SectionEmpty;