import React, {useContext} from 'react';
import StoreNav, {NavTypes} from "../store/StoreNavMenu";
import NavDesktop, {INavDesktop} from "../navigation/navs/NavDesktop";
import {Link} from "react-router-dom";
import {ContextAuth} from "../provider/AuthProvider";


const MenuDesktop = () => {
    const {mainShortname} = useContext(ContextAuth)

    const menus: INavDesktop[] = [
        {
            id: NavTypes.relations,
            icon: 'relations',
            text: 'Связи',
            value: '/relations',
        }, {
            id: NavTypes.myProfile,
            icon: 'my-profile',
            text: 'Мой профиль',
            value: `/${mainShortname}`,
        },
    ]

    return (
        <div className={'w-[275px] max-md:w-fit max-sm:hidden h-fit sticky top-0'}>
            <div className={'flex h-[52px] px-[20px]'}>
                <Link className={'text-[20px] my-auto max-md:hidden cursor-pointer'} to={'/my'}
                      onClick={() => StoreNav.setNav(NavTypes.myProfile)}>Yetap</Link>
                <Link className={'text-[20px] mx-auto my-auto md:hidden cursor-pointer'} to={'/my'}
                      onClick={() => StoreNav.setNav(NavTypes.myProfile)}>Y</Link>
            </div>
            <div className={'px-[8px]'}>
                {menus.map(x => <NavDesktop key={x.id} {...x}/>)}
            </div>
        </div>
    );
};

export default MenuDesktop;