import React from 'react';

interface IContainer {
    invisible?: boolean,
    className?: string
    children: React.ReactNode
}

const ContainerDull: React.FC<IContainer> = ({invisible, className, children}) => {
    return (
        <div
            className={`${className} ${!invisible && 'bg-white bg-opacity-[85%] backdrop-blur-[12px] border-divider'} ${invisible ? 'absolute' : 'sticky'} w-full`}>
            <div
                className={'flex flex-row items-center w-full h-[52px]'}>
                {children}
            </div>
        </div>
    );
};

export default ContainerDull;