import React from 'react';
import Svg from "../../media/Svg";
import {observer} from "mobx-react-lite";
import StoreNavMenu from "../../store/StoreNavMenu";
import LinkFrom from "../../custome/LinkFrom";

export interface INavDesktop {
    id: number,
    icon: string,
    text: string,
    value: string
}

const NavDesktop: React.FC<INavDesktop> = observer(({id, icon, text, value: value}) => {
    return (
        <LinkFrom to={value} onClick={() => StoreNavMenu.setNav(id)} className={'max-sm:flex max-sm:flex-1'}>
            <div className={'max-sm:m-auto py-[4px]'}>
                <div className={'p-[12px] w-fit rounded-full hover:bg-button_hover'}>
                    <div className={'flex flex-row items-center'}>
                        <Svg className={'h-[26.25px]'}
                             name={`${icon}_${!(StoreNavMenu.getNav() == id) ? 'not-' : ''}active`}/>
                        <div className={'pl-[20px] pr-[16px] max-md:hidden'}>
                            <span
                                className={`${(StoreNavMenu.getNav() == id) && 'font-bold'} text-[20px]`}>{text}</span>
                        </div>
                    </div>
                </div>
            </div>
        </LinkFrom>
    )
})

export default NavDesktop;