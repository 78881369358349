import React from 'react';

interface IWrapper {
    url: string | null;
    className?: string;
    pb?: string;
}

const WrapperImage: React.FC<IWrapper> = ({url, className, pb = 'pb-[100%]'}) => {
    return (
        <div className={`${className} parent relative w-full`}>
            <div
                style={{
                    backgroundImage: url ? `url(${url})` : '',
                    backgroundPosition: 'center',
                    backgroundSize: 'cover'
                }}
                className={`${pb} child w-full bg-cover ${className}`}>
            </div>
        </div>
    );
};

export default WrapperImage;
