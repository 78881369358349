import React, {useContext} from 'react';
import {Outlet} from "react-router-dom";
import MenuDesktop from "../menus/MenuDesktop";
import MenuMobile from "../menus/MenuMobile";
import Banner from "../componts/Banner";
import {ContextAuth} from "../provider/AuthProvider";

const WrapperMain = () => {
    const {isAuth} = useContext(ContextAuth)
    return (
        <div className={'flex flex-col'}>
            <div className={'flex mx-auto max-sm:w-full'}>
                {isAuth && <MenuDesktop/>}
                <div className={'flex flex-row space-x-[30px] max-sm:w-full min-h-screen'}>
                    <div className={'flex flex-col w-[600px] max-sm:w-full border-l border-r max-sm:border-0'}>
                        <div className={'flex-1'}>
                            <Outlet/>
                        </div>
                        {isAuth && <MenuMobile/>}
                    </div>
                    {isAuth && <div className={'w-[420px] max-lg:hidden'}/>}
                </div>
            </div>
            {!isAuth && <Banner/>}
        </div>
    );
};

export default WrapperMain;