import React from "react";

export function getLastElement(arr: any[]) {
    if (arr.length > 0) {
        return arr[arr.length - 1];
    } else {
        return undefined;
    }
}

const token = 'token'
export const setToken = (jwt: string) => {
    localStorage.setItem(token, jwt)
}
export const isNumeric = (str: string): boolean => {
    return !isNaN(Number(str));
}
export const handleSubmitWithPreventDef = (ref: React.RefObject<HTMLFormElement>) => {
    ref.current?.dispatchEvent(new Event('submit', {cancelable: true, bubbles: true}))
}
export const getToken = () => {
    return localStorage.getItem(token)
}
export const toFlow = (value: string) => {
    const pathname = window.location.pathname
    const pathname1 = pathname.endsWith('/') ? pathname.slice(0, -1) : pathname;
    const pathname2 = value.startsWith('/') ? value.slice(1) : value;
    return pathname1.split('/flow')[0] + '/flow/' + pathname2
}
export const downloadFile = (url: string) => {
    console.log(url)
    const link = document.createElement('a');
    link.href = url;
    link.download = 'filename.txt'; // Замените на желаемое имя файла
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};