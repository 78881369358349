import React, {useContext, useRef, useState} from 'react';
import {TextField, ThemeProvider} from "@mui/material";
import Background from "../../componts/Background";
import Avatar from "../../componts/Avatar";
import SettingItem from "../../items/settings/SettingItem";
import {settings} from "./ModalSettings";
import {SubmitHandler, useForm} from "react-hook-form";
import WrapperForm from "../../wrappers/WrapperForm";
import ServiceCard from "../../serivices/ServiceCard";
import {useNavigate, useParams} from "react-router-dom";
import Toolbar from "../../componts/Toolbar";
import {ScreenSize} from "../../const/screen";
import {handleSubmitWithPreventDef, toFlow} from "../../functions";
import StoreCardEdit from "../../store/StoreCard";
import {textFieldTheme} from "../../theme";
import {ContextModal} from "../../provider/ModalProvider";

interface IForm {
    city: string,
    companyName: string,
    description: string,
    name: string,
    title: string
    cardName?: string,
    password?: string,
}

const ModalEditMain = () => {
    const {closeModal} = useContext(ContextModal)
    const formRef = useRef<HTMLFormElement>(null)
    const {shortname_param} = useParams()
    const shortname = shortname_param!!
    const {
        register, handleSubmit, reset,
        formState: {errors}
    } = useForm<IForm>()
    const onSubmit: SubmitHandler<IForm> = async data => {
        try {
            const main = (await ServiceCard.putCard(shortname, data)).data
            StoreCardEdit.setMain(main)
            closeModal()
        } catch (e) {

        }
    }
    return (
        <div>
            <Toolbar
                text={'Главное'}
                contextType={'FLOW'}
                btnRemove={window.innerWidth <= ScreenSize.md ? {} : null}
                btnAction={{
                    text: 'Сохранить',
                    onClick: () => handleSubmitWithPreventDef(formRef)
                }}/>
            <div className={'flex flex-col'}>
                <Background chooseType={'EDIT'}/>
                <div className={'px-[16px]'}>
                    <div className={'flex justify-between h-[68px] max-sm:h-[48px]'}>
                        <div className={'flex w-1/4 relative'}>
                            <div className={'flex flex-row items-end absolute h-full w-full'}>
                                <Avatar chooseType={'EDIT'}/>
                            </div>
                        </div>
                    </div>
                    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                        <ThemeProvider theme={textFieldTheme}>
                            <TextField
                                label={'Имя'}
                                defaultValue={StoreCardEdit.getName()}
                                {...register('name', {required: "Это обязательное поле"})}
                            />
                            <TextField
                                label={'Должность'}
                                defaultValue={StoreCardEdit.getTitle()}
                                {...register('title', {required: "Это обязательное поле"})}
                            />
                            <TextField
                                label={'Компания'}
                                defaultValue={StoreCardEdit.getCompanyName()}
                                {...register('companyName', {required: "Это обязательное поле"})}
                            />
                            <TextField
                                label={'Город'}
                                defaultValue={StoreCardEdit.getCity()}
                                {...register('city', {required: "Это обязательное поле"})}
                            />
                            <TextField
                                label={'Описание'}
                                multiline
                                defaultValue={StoreCardEdit.getDescription()}
                                {...register('description', {required: "Это обязательное поле"})}
                            />
                        </ThemeProvider>
                    </form>
                </div>
            </div>
            <div className={'hidden max-md:block'}>
                {settings.slice(1).map(x => <SettingItem key={x.id} {...x} active={false}/>)}
            </div>
        </div>
    )

};

export default ModalEditMain;