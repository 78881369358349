import $api from "../api/interceptor";
import {IJwt} from "../interface/IJwt";

export default class ServiceAuthorization {

    public static signIn(username: string, password: string) {
        return $api.post<IJwt>(`/signin`, {
            username,
            password
        })
    }

    public static signUp(name: string, username: string, password: string) {
        return $api.post<IJwt>(`/signup`, {
            name,
            username,
            password
        })
    }

}