import React from 'react';
import Svg from "../../media/Svg";
import StoreNav from "../../store/StoreNavMenu";
import {Link} from "react-router-dom";
import {observer} from "mobx-react-lite";
import LinkFrom from "../../custome/LinkFrom";

export interface INavMobile {
    id: number,
    icon: string,
    value: string
}

const NavMobile: React.FC<INavMobile> = observer(({id, icon, value: value}) => {
    return (
        <LinkFrom className={'flex-1'} to={value}>
            <div className={'flex h-full'}>
                <div className={'m-auto flex w-[42.25px] aspect-square'}>
                    <Svg className={'h-[26.25px] m-auto'}
                         name={`${icon}_${!(StoreNav.getNav() == id) ? 'not-' : ''}active`}/>
                </div>
            </div>
        </LinkFrom>
    );
})

export default NavMobile;