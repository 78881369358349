import {createTheme} from "@mui/material";

export const textFieldTheme = createTheme({
    components: {
        MuiTextField: {
            styleOverrides: {
                root: {
                    width: '100%',
                    margin: '12px 0 12px 0',
                    border: "black"
                }
            },
            defaultProps: {
                variant: 'outlined',
            },
        },
    },
    palette: {
        primary: {
            main: '#FF643A',
            light: '#CFD9DE',
        },
        secondary: {
            main: '#000000',
            light: '#BDBDBD',
        },
        error: {
            main: '#D84B4B',
        },
        info: {
            main: '#F5F5F5',
        },
        text: {
            primary: '#000000',
            secondary: '#6A6A6A',
        },
    },
});