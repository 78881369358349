import React from 'react';
import WrapperComponent from "../wrappers/WrapperComponent";
import {IEducation, IExperience} from "../interface/IResume";

interface IItem extends IEducation {

}

const EducationItem: React.FC<IItem> = ({institution,specialization,stage,graduationAt}) => {
    return (
        <WrapperComponent className={'px-[16px]'}>
            <div className={'space-y-[4px]'}>
                <p className={'font-bold'}>{institution}</p>
                <p className={'text-secondary'}>{stage}</p>
                <p className={'text-secondary'}>{specialization}</p>
                <p className={'text-secondary'}>{graduationAt}</p>
            </div>
        </WrapperComponent>
    );
};

export default EducationItem;