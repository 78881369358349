import React from 'react';
import {Link} from "react-router-dom";
import {IGetMedia} from "../interface/IMedia";
import {toFlow} from "../functions";
import LinkFrom from "../custome/LinkFrom";

interface IItem extends IGetMedia{

}
const MediaItem:React.FC<IItem> = ({url}) => {
    return (
        <LinkFrom to={toFlow(`/medias?url=${url}`)} className={'w-full aspect-square bg-empty'}>
            <img className={'w-full h-full object-cover'} src={url} alt={'media'}/>
        </LinkFrom>
    );
};

export default MediaItem;