import {makeAutoObservable} from "mobx";

export const NavTypes = {
    relations: 0,
    myProfile: 1,
} as const;

class StoreNavMenu {
    private nav: number | null = null

    constructor() {
        makeAutoObservable(this)
    }

    getNav() {
        return this.nav
    }

    setNav(id: number) {
        this.nav = id
    }

    clear() {
        this.nav = null
    }
}

export default new StoreNavMenu();