import React from 'react';

interface ISvg {
    className?: string
    name: string
}

const Svg: React.FC<ISvg> = ({className, name}) => {
    return (
        <img className={`${className} aspect-square`} src={`/svg/svg_${name}.svg`} alt={'svg'}/>
    );
};

export default Svg;