import React from 'react';
import Svg from "../media/Svg";
import {getClodUrl} from "../const/url";


interface IItem {
    value: string,
    icon: string
    type: string
}

const CardDetailItem: React.FC<IItem> = ({icon,type, value}) => {
    return (
        <button className={'flex flex-row items-center space-x-[4px]'}>
            <img className={'w-[18.75] aspect-square'} src={icon} alt={'icon'}/>
            <span className={'text-[15px] text-secondary text-start'}>
                {value}
            </span>
        </button>
    );
};

export default CardDetailItem;