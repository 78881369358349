import React from 'react';

interface IWrapper {
    className?: string,
    onClick?: () => void
    children: React.ReactNode
}

const WrapperComponent: React.FC<IWrapper> = ({className, children, onClick}) => {
    return (
        <div className={`${className} pt-[4px] pb-[12px] w-full`} onClick={onClick && onClick}>
            {children}
        </div>
    );
};

export default WrapperComponent;