import React, {ChangeEvent, useRef, useState} from 'react';
import StoreCardEdit from "../store/StoreCard";
import {observer} from "mobx-react-lite";
import {getClodUrl} from "../const/url";
import Svg from "../media/Svg";
import ServiceCard from "../serivices/ServiceCard";
import {useNavigate, useParams} from "react-router-dom";
import {toFlow} from "../functions";


interface IComponent {
    chooseType: 'CARD' | 'EDIT'
}

const Avatar: React.FC<IComponent> = observer(({chooseType}) => {
    const {shortname} = useParams()
    const [getHover, setHover] = useState(false)
    const navigate = useNavigate()
    const fileInputRef = useRef<HTMLInputElement>(null)
    const handleClick = () => {
        if (fileInputRef.current) fileInputRef.current.click()
    }
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]
        if (file) ServiceCard.putAvatar(shortname!!, file)
            .then(res => StoreCardEdit.setAvatar(res.data.url))
    }
    return (
        <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => (chooseType == 'CARD' && StoreCardEdit.isAvatarUrl()) && navigate(toFlow(`/medias?url=${StoreCardEdit.getAvatarUrl()}`))}
            className={'w-full overflow-hidden relative bg-empty aspect-square rounded-full border-[4px] max-sm:border-[3px] border-white'}
        >
            <input className={'hidden'}
                   type="file"
                   ref={fileInputRef}
                   accept=".jpg, .jpeg, .png"
                   onChange={handleFileChange}
            />
            <img
                src={StoreCardEdit.isAvatarUrl() ? StoreCardEdit.getAvatarUrl()!! : getClodUrl('empty.png')}
                className={'w-full h-full object-cover'} alt={'avatar'}/>
            {(getHover && chooseType == 'CARD' && !StoreCardEdit.isAvatarUrl() || chooseType=='EDIT') &&
                <div className={'flex absolute top-0 left-0 w-full h-full bg-black bg-opacity-30'}>
                    <div onClick={handleClick}
                         className={'flex m-auto h-[44px] cursor-pointer aspect-square bg-dark rounded-full opacity-[75%]'}>
                        <div className={'flex m-auto h-[22px] aspect-square'}>
                            <Svg name={'camera'}/>
                        </div>
                    </div>
                </div>}
        </div>
    );
})

export default Avatar;