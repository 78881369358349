import React, {useEffect, useState} from 'react';
import Toolbar from "../componts/Toolbar";
import {IToolbar} from "../interface/IToolbar";
import Button from "../componts/Button";

interface IWrapper {
    loading?: boolean,
    children: React.ReactNode,
    toolbarText: string,
    btnAction?: {
        text: string,
        onClick?: () => void
    },
}

const WrapperModal: React.FC<IWrapper> = ({
                                              children,
                                              loading = false,
                                              toolbarText,
                                              btnAction
                                          }) => {

    const [isScrolledToBottom, setScrolledToBottom] = useState<boolean>()
    useEffect(() => {
        const modal_scroll_wrapper = document.getElementById('modal_scroll_wrapper');
        if (modal_scroll_wrapper) {
            setScrolledToBottom(modal_scroll_wrapper.scrollHeight > modal_scroll_wrapper.clientHeight)
            modal_scroll_wrapper.addEventListener('scroll', () => {
                const isScrolledToBottom = modal_scroll_wrapper.scrollTop >= (modal_scroll_wrapper.scrollHeight - modal_scroll_wrapper.clientHeight) * 0.99;
                setScrolledToBottom(!isScrolledToBottom)
            })
        } else setScrolledToBottom(false)

    }, []);


    return (
        <div
            className={`flex flex-col relative w-[600px] max-sm:w-full h-[650px] max-sm:h-full bg-white rounded-[12px] max-sm:rounded-[0px] overflow-hidden`}>
            {!loading && <>
                <div id={'modal_scroll_wrapper'} className={'flex flex-1 flex-col overflow-auto'}>
                    <Toolbar
                        contextType={'FLOW'}
                        text={toolbarText}/>
                    <div className={'flex flex-1'}>
                        {children}
                    </div>
                </div>
                {btnAction &&
                    <div className={`${isScrolledToBottom && 'shadow-modal'} py-[24px] px-[80px] max-sm:px-[32px]`}>
                        <Button type={'submit'} format_type={'lg_main_full'} content={btnAction.text}
                                onClick={btnAction.onClick}/>
                    </div>}
            </>}
        </div>
    )

};


export default WrapperModal;
