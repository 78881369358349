import React, {useContext, useRef, useState} from 'react';
import WrapperModal from "../../wrappers/WrapperModal";
import {Link, useLocation, useNavigate, useSearchParams} from "react-router-dom";
import {SubmitHandler, useForm} from "react-hook-form";
import ServiceAuthorization from "../../serivices/ServiceAuthorization";
import {TextField, ThemeProvider} from "@mui/material";
import WrapperForm from "../../wrappers/WrapperForm";
import {handleSubmitWithPreventDef, toFlow} from "../../functions";
import {ContextAuth} from "../../provider/AuthProvider";
import {ContextModal} from "../../provider/ModalProvider";
import LinkFrom from "../../custome/LinkFrom";
import {textFieldTheme} from "../../theme";

interface IForm {
    username: string;
    password: string;
}


const ModalSignIn: React.FC = () => {
    const formRef = useRef<HTMLFormElement>(null)
    const {authorize} = useContext(ContextAuth)
    const {closeModal} = useContext(ContextModal)
    const location = useLocation()
    const [getSearchParams, setSearchParams] = useSearchParams()
    const [getLoading, setLoading] = useState<boolean>()
    const navigate = useNavigate()
    const {
        register, handleSubmit, reset,
        formState: {errors}
    } = useForm<IForm>()
    const onSubmit: SubmitHandler<IForm> = async data => {
        try {
            setLoading(true)
            const jwt = (await ServiceAuthorization.signIn(data.username, data.password)).data
            authorize!!(jwt)
            if (location.pathname.includes('/flow')) closeModal()
            else navigate('/my')
        } catch (e) {
            setLoading(false)
        }
    }
    return (
        <WrapperModal
            btnAction={{
                text: 'Войти',
                onClick: () => handleSubmitWithPreventDef(formRef)
            }}
            loading={getLoading}
            toolbarText={'Yetap'}>
            <div className={'px-[80px] max-sm:px-[32px] w-full flex flex-col flex-1'}>
                <div className={'py-[20px]'}>
                    <span className={'font-bold text-[31px] max-sm:text-[26px]'}>Вход в учетную запись</span>
                </div>
                <div className={'flex-1'}>
                    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                        <ThemeProvider theme={textFieldTheme}>
                            <TextField
                                label={'Почта'}
                                {...register('username', {required: "Это обязательное поле"})}
                            />
                            <TextField
                                label={'Пароль'}
                                {...register('password', {required: "Это обязательное поле"})}
                            />
                        </ThemeProvider>
                    </form>
                    <div className={'flex w-full'}>
                        <LinkFrom to={location.pathname.includes('/flow') ? toFlow('/signup') : '/signup'}
                                  className={'ml-auto text-[14.3px] text-main'}>Создать визитку</LinkFrom>
                    </div>
                </div>
            </div>
        </WrapperModal>
    );
};

export default ModalSignIn;