import React, {useEffect} from 'react';
import ContactItem from "../items/contacts/ContactItem";
import {useParams} from "react-router-dom";
import ServiceCard from "../serivices/ServiceCard";
import {IGetContact} from "../interface/IContact";
import {toFlow} from "../functions";
import StoreCardEdit from "../store/StoreCard";
import Loader from "../componts/Loader";
import {observer} from "mobx-react-lite";
import SectionEmpty from "./SectionEmpty";
import WrapperSection from "../wrappers/WrapperSection";
import {offline} from "../App";

export const contacts: IGetContact[] = [
    {
        id: 1,
        value: 'https://t.me/dv_obukhov',
        title: 'Telegram',
        type: 'TELEGRAM',
        description: 'Основной способ связи',
        logoUrl: "https://s3.timeweb.com/2cc1de15-bc1f377d-9e5a-448f-8a1d-f117b93916d2/img_telegram.svg"
    },
    {
        id: 2,
        value: 'tel:+79119516330',
        description: "Звонить с 11:00 до 19:00",
        title: 'Телефон',
        type: 'PHONE',
        logoUrl: "https://s3.timeweb.com/2cc1de15-bc1f377d-9e5a-448f-8a1d-f117b93916d2/img_phone.svg"
    },
    {
        id: 3,
        value: 'mailto:dv_obukhov@yandex.ru',
        title: 'Почта',
        type: 'MAIL',
        logoUrl: "https://s3.timeweb.com/2cc1de15-bc1f377d-9e5a-448f-8a1d-f117b93916d2/img_mail.svg"
    },
    {
        id: 4,
        value: 'https://vk.com/dv_obukhov',
        title: 'ВКонтакте',
        type: 'phone',
        logoUrl: "https://s3.timeweb.com/2cc1de15-bc1f377d-9e5a-448f-8a1d-f117b93916d2/img_vk.svg"
    },
    {
        id: 5,
        value: 'https://api.whatsapp.com/send?phone=79119561330',
        title: 'WhatsApp',
        type: 'phone',
        logoUrl: "https://s3.timeweb.com/2cc1de15-bc1f377d-9e5a-448f-8a1d-f117b93916d2/img_whatsapp.svg"
    }
]

const SectionContacts: React.FC = observer(() => {
    const {shortname_param, type_param} = useParams()
    const shortname = shortname_param!!
    const initContacts = (contacts: IGetContact[]) => {
        StoreCardEdit.setContacts(contacts)
    }
    useEffect(() => {
        if (!StoreCardEdit.isContactsInit()) {
            if (offline)
                initContacts(contacts)
            else
                ServiceCard.getContacts(shortname)
                    .then(res => {
                        initContacts(res.data)
                    })
        }
    }, [shortname_param]);
    return (
        StoreCardEdit.isContactsInit() ?
            StoreCardEdit.getContacts()!!.length > 0 ?
                <WrapperSection className={'flex flex-row flex-wrap w-full justify-center'}>
                    {StoreCardEdit.getContacts()!!.map(x => <ContactItem key={x.id} {...x}/>)}
                </WrapperSection> :
                <SectionEmpty
                    title={'Вы ещё не добавили контакты'}
                    description={'Расскажите гостям вашей страницы как можно с вами связаться'}
                    btnAction={{
                        text: 'Добавить контакты',
                        to: {
                            value: toFlow('/edit/contacts/types')
                        }
                    }}
                /> :
            <Loader/>
    )
})

export default SectionContacts;