import React, {ChangeEvent, useEffect, useRef} from 'react';
import MediaItem from "../../items/MediaItem";
import Toolbar from "../../componts/Toolbar";
import {ScreenSize} from "../../const/screen";
import {useParams} from "react-router-dom";
import ServiceCard from "../../serivices/ServiceCard";
import StoreCardEdit from "../../store/StoreCard";
import SectionEmpty from "../../sections/SectionEmpty";
import {toFlow} from "../../functions";
import Loader from "../../componts/Loader";
import {observer} from "mobx-react-lite";

const ModalEditMedias = observer(() => {
    const {shortname_param} = useParams()
    const shortname = shortname_param!!
    const fileInputRef = useRef<HTMLInputElement>(null)
    useEffect(() => {
        StoreCardEdit.getMedias() == undefined && ServiceCard.getMedias(shortname)
            .then(res => {
                StoreCardEdit.setMedias(res.data)
            })
    }, [shortname_param]);
    const handleClick = () => {
        if (fileInputRef.current) fileInputRef.current.click()
    }
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]
        if (file) ServiceCard.postMedia(shortname!!, file)
            .then(res => StoreCardEdit.appendMedia(res.data))
    }
    return (
        <>
            <input className={'hidden'}
                   type="file"
                   ref={fileInputRef}
                   accept=".jpg, .jpeg, .png"
                   onChange={handleFileChange}
            />
            <Toolbar
                text={'Медиа'}
                contextType={'FLOW'}
                btnRemove={window.innerWidth <= ScreenSize.md ? {} : null}
                btnAction={{
                    text: 'Добавить',
                    onClick: handleClick
                }}/>
            {StoreCardEdit.getMedias() != undefined ?
                StoreCardEdit.isMedias() ? <div className={'grid grid-cols-3 w-full gap-[2px]'}>
                        {StoreCardEdit.getMedias()!!.map(x => <MediaItem key={x.id} {...x}/>)}
                    </div> :
                    <SectionEmpty
                        title={'Вы ещё не добавили медиа'}
                        description={'Наполните профиль необходимым контентом для гостей вашей страницы'}
                    /> :
                <Loader/>}
        </>
    )
})

export default ModalEditMedias;