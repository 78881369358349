import React, {useEffect} from 'react';
import WrapperComponent from "../../wrappers/WrapperComponent";
import Svg from "../../media/Svg";
import WrapperImage from "../../wrappers/WrapperImage";
import {IGetContact} from "../../interface/IContact";

interface IItem extends IGetContact {

}


const ContactItem: React.FC<IItem> = ({value, logoUrl, title, description}) => {
    return (
        description ?
            <a href={value} className={'flex flex-row items-center w-full pt-[4px] pb-[12px]'}>
                <div className={'w-[16px]'}></div>
                <div className={'contact_logo'}>
                    <WrapperImage className={'rounded-[20px]'} url={logoUrl}/>
                </div>
                <div className={'flex flex-col items-start px-[12px] flex-1'}>
                    <p>{title}</p>
                    <p className={'text-secondary'}>{description}</p>
                </div>
                <div className={'w-[16px]'}></div>
            </a> :
            <a href={value} className={'contact_logo mx-[6px] pt-[4px] pb-[8px]'}>
                <div className={'flex flex-col items-center'}>
                    <WrapperImage className={'rounded-[20px]'} url={logoUrl} pb={'pb-[100%]'}/>
                    <p className={'mt-[10px]'}>{title}</p>
                </div>
            </a>
    )

}

export default ContactItem;