import React from 'react';
import {ITab} from "../../interface/ITab";
import {Link, useLocation} from "react-router-dom";

interface IComponent extends ITab {
    active: boolean
    onChoose: () => void
}

const Tab: React.FC<IComponent> = ({id, type, writing, active,onChoose}) => {
    const location = useLocation();
    const link = id != 0 ? type.toLowerCase() : ''
    const pathSegments = location.pathname.split('/').slice(1);
    const lastSegment = pathSegments[pathSegments.length - 1];

    return (
        <button onClick={onChoose} className={'flex h-full flex-1'}>
            <div className={'mx-auto mt-auto h-[40px] relative'}>
                <div className={'px-[5px]'}>
                    <span className={`${!active && 'text-secondary'} text-[15px] font-medium`}>{writing}</span>
                </div>
                {active && <div className={'h-[4px] absolute bottom-0 left-0 w-full rounded-full bg-main'}/>}
            </div>
        </button>
    );
}

export default Tab;