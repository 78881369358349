export const TabTypes = {
    CONTACTS: 'CONTACTS',
    RESUME: 'RESUME',
    MEDIAS: 'MEDIAS',
    FILES: 'FILES',
} as const;

export interface ITab {
    id: number,
    order: number,
    type: string,
    writing: string
}
