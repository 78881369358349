import React, {ChangeEvent, useRef, useState} from 'react';
import WrapperImage from "../wrappers/WrapperImage";
import StoreCardEdit from "../store/StoreCard";
import {observer} from "mobx-react-lite";
import Svg from "../media/Svg";
import ServiceCard from "../serivices/ServiceCard";
import {useNavigate, useParams} from "react-router-dom";
import {toFlow} from "../functions";

interface IComponent {
    chooseType: 'CARD' | 'EDIT'
}

const Background: React.FC<IComponent> = observer(({chooseType}) => {
    const {shortname} = useParams()
    const [getHover, setHover] = useState(false)
    const fileInputRef = useRef<HTMLInputElement>(null)
    const navigate = useNavigate()
    const handleClick = () => {
        if (fileInputRef.current) fileInputRef.current.click()
    }
    const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
        const file = event.target.files?.[0]
        if (file) ServiceCard.putBackground(shortname!!, file)
            .then(res => StoreCardEdit.setBackground(res.data.url))
    }
    return (
        <div
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() =>(chooseType == 'CARD' && StoreCardEdit.isBackgroundUrl()) && navigate(toFlow(`/medias?url=${StoreCardEdit.getBackgroundUrl()}`))}
            className={'w-full bg-empty relative'}>
            <input className={'hidden'}
                   type="file"
                   ref={fileInputRef}
                   accept=".jpg, .jpeg, .png"
                   onChange={handleFileChange}
            />
            <WrapperImage url={StoreCardEdit.getBackgroundUrl()} pb={'pb-[33%]'}/>
            {(getHover && chooseType == 'CARD' && !StoreCardEdit.isBackgroundUrl() || chooseType=='EDIT') &&
                <div className={'flex absolute top-0 left-0 w-full h-full bg-black bg-opacity-30'}>
                    <div onClick={handleClick}
                         className={'flex m-auto h-[44px] cursor-pointer aspect-square bg-dark rounded-full opacity-[75%]'}>
                        <div className={'flex m-auto h-[22px] aspect-square'}>
                            <Svg name={'camera'}/>
                        </div>
                    </div>
                </div>}
        </div>
    )
})

export default Background;