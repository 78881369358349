import Button from "./Button";
import {toFlow} from "../functions";

const Banner = () => {
    return (
        <div
            className={`z-90 flex flex-1 flex-col space-y-[14px] md:space-y-0 md:flex-row md:items-center p-[14px] h-fit sticky bottom-0 left-0 bg-main`}>
            <div className={'hidden md:block flex-1'}>
            </div>
            <div className={'flex flex-col h-fit sm:w-[600px]'}>
                <p className={'text-[23px] font-bold text-white'}>Создайте свою визитку</p>
                <p className={'text-[14px] text-white'}>Впечатлите своих партнеров и клиентов при первой встречи</p>
            </div>
            <div className={'flex flex-1 flex-row md:justify-end h-fit'}>
                <div className={'flex flex-row space-x-[8px] m-0 md:mr-[86px]'}>
                    <Button content={'Войти'} format_type={'sm_transparent_border'} to={{
                        value: toFlow('/signin')
                    }}/>
                    <Button content={'Создать'} format_type={'sm_white'} to={{
                        value: toFlow('/signup')
                    }}/>
                </div>
            </div>
        </div>
    )
};
export default Banner