import React from 'react';
import {Link} from "react-router-dom";
import WrapperComponent from "../wrappers/WrapperComponent";
import LinkFrom from "../custome/LinkFrom";

const RelationItem = () => {
    return (
        <LinkFrom to={'/gfb'}>
            <div className={'w-full px-[16px]'}>
                <WrapperComponent>
                    <div className={'flex flex-row space-x-[12px]'}>
                        <div className={'h-[40px] aspect-square rounded-full border'}>

                        </div>
                        <div className={'flex-1'}>
                            <p className={'font-bold'}>Pavel Ivanov</p>
                            <p className={'text-secondary'}>Яндекс · Санкт-Петербург</p>
                            <div className={'pt-[4.8px] pb-[0.8px]'}>
                                <span>Мобильны разработчик</span>
                            </div>
                        </div>
                    </div>
                </WrapperComponent>
            </div>
        </LinkFrom>
    );
};

export default RelationItem;