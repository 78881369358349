import React from 'react';
import WrapperComponent from "../wrappers/WrapperComponent";
import {IExperience} from "../interface/IResume";

interface IItem extends IExperience {

}

const ExperienceItem: React.FC<IItem> = ({position, company, startAt, finishAt}) => {
    return (
        <WrapperComponent className={'px-[16px]'}>
            <div className={'space-y-[4px]'}>
                <p className={'font-bold'}>{position}</p>
                <p className={'text-secondary'}>{company}</p>
                <p className={'text-secondary'}>{`${startAt}${finishAt ? (' - ' + finishAt) : ''}`}</p>
            </div>
        </WrapperComponent>
    );
};

export default ExperienceItem;