import $api from "../api/interceptor";
import {IGetCard, IGetCardMain, IPutCard} from "../interface/ICard";
import {IGetContact, IPostContact, IPutContact} from "../interface/IContact";
import {IResume, IGetSkill} from "../interface/IResume";
import {IGetFile} from "../interface/IFile";
import {IGetQr} from "../interface/IQr";

export default class ServiceCard {
    public static getCard(shortname: string) {
        return $api.get<IGetCard>(`/cards/${shortname}`)
    }

    public static putAvatar = (shortname: string, value: File) => {
        const formData = new FormData()
        formData.append('file', value)
        return $api.put<IGetFile>(`/cards/${shortname}/avatar`, formData);
    }
    public static putBackground = (shortname: string, value: File) => {
        const formData = new FormData();
        formData.append('file', value);
        return $api.put<IGetFile>(`/cards/${shortname}/background`, formData);
    }

    public static getMedias(shortname: string) {
        return $api.get<IGetFile[]>(`/cards/${shortname}/medias`)
    }

    public static postMedia(shortname: string, value: File) {
        const formData = new FormData();
        formData.append('file', value);
        return $api.post<IGetFile>(`/cards/${shortname}/media`, formData)
    }

    public static postFile(shortname: string, value: File) {
        const formData = new FormData();
        formData.append('file', value);
        return $api.post<IGetFile>(`/cards/${shortname}/files`, formData)
    }

    public static getFiles(shortname: string) {
        return $api.get<IGetFile[]>(`/cards/${shortname}/files`)
    }

    public static getQr(shortname: string) {
        return $api.get<IGetQr>(`/cards/${shortname}/qr`)
    }

    public static putCard(shortname: string, value: IPutCard) {
        return $api.put<IGetCardMain>(`/cards/${shortname}`, value)
    }

    public static getContacts(shortname: string) {
        return $api.get<IGetContact[]>(`/cards/${shortname}/contacts`)
    }

    public static getResume(shortname: string) {
        return $api.get<IResume>(`/cards/${shortname}/resume`)
    }

    public static postContact(shortname: string, value: IPostContact) {
        return $api.post<IGetContact>(`/cards/${shortname}/contacts`, value)
    }

    public static postSkill(shortname: string, value: string) {
        return $api.post<IGetSkill>(`/cards/${shortname}/skills`, value)
    }
    public static deleteSkill(shortname: string, id: number) {
        return $api.delete(`/cards/${shortname}/skills/${id}`)
    }

    public static putContact(shortname: string, value: IPutContact) {
        return $api.put<IGetContact>(`/cards/${shortname}/contacts`, value)
    }

}