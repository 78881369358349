import React, {useEffect} from 'react';
import FileItem from "../items/FileItem";
import {IGetFile} from "../interface/IFile";
import {useParams} from "react-router-dom";
import ServiceCard from "../serivices/ServiceCard";
import {toFlow} from "../functions";
import StoreCardEdit from "../store/StoreCard";
import SectionEmpty from "./SectionEmpty";
import Loader from "../componts/Loader";
import {observer} from "mobx-react-lite";
import WrapperSection from "../wrappers/WrapperSection";

const files: IGetFile[] = [
    {
        id: 1,
        albumId: 1,
        description: null,
        name: 'Yetap Тизер',
        size: '24МБ',
        type: 'DOCX',
        typeColor: '#104F7A',
        url: 'Yetap - тизер.docx'
    },
    {
        id: 2,
        albumId: 1,
        description: null,
        name: 'Yetap Презентация',
        size: '18МБ',
        type: 'PPTX',
        typeColor: '#DC7F1C',
        url: 'Yetap - презентация.pptx'
    },
    {
        id: 3,
        albumId: 1,
        description: null,
        name: 'Yetap - юнит экономика',
        size: '14МБ',
        type: 'XLSX',
        typeColor: '#0D683E',
        url: 'Yetap - юнит экономика.xlsx'
    },
    {
        id: 4,
        albumId: 1,
        description: null,
        name: 'Yetap - пример таблицы',
        size: '10МБ',
        type: 'XLSX',
        typeColor: '#0D683E',
        url: 'Yetap - пример таблицы.xlsx'
    },
]
const SectionFiles = observer(() => {
    const {shortname_param, type_param} = useParams()
    const shortname = shortname_param
    useEffect(() => {
        StoreCardEdit.getFiles() == undefined && ServiceCard.getFiles(shortname!!)
            .then(res => {
                StoreCardEdit.setFiles(res.data)
            })
    }, [shortname_param]);
    return (
        StoreCardEdit.getFiles() != undefined ?
            StoreCardEdit.isFiles() ? <WrapperSection>
                    {StoreCardEdit.getFiles()!!.map(x => <FileItem key={x.id} {...x}/>)}
                </WrapperSection> :
                <SectionEmpty
                    title={'Вы ещё не добавили файлы'}
                    description={'Наполните профиль необходимым контентом для гостей вашей страницы'}
                    btnAction={{
                        text: 'Добавить файлы',
                        to: {
                            value: toFlow('/edit/files')
                        }
                    }}
                /> :
            <Loader/>
    )

})

export default SectionFiles;