import React, {useEffect, useState} from 'react';
import {IJwt} from "../interface/IJwt";
import {useLocation} from "react-router-dom";

interface IContext {
    isAuth?: boolean,
    mainShortname?: string,
    accountId?: number,
    authorize: (jwt: IJwt) => void
}

export const ContextAuth = React.createContext<IContext>({
    authorize: (jwt: IJwt) => console.log('authorize')
})
interface IProvider {
    children: React.ReactNode
}

export const token = 'token'
const mainShortname = 'mainShortname'
const accountId = 'accountId'
const AuthProvider: React.FC<IProvider> = ({children}) => {
    const location=useLocation()

    const [getAuth, setAuth] = useState<IJwt | null>()
    useEffect(() => {
        const jwt = localStorage.getItem(token) ? {
            token: localStorage.getItem(token)!!,
            mainShortname: localStorage.getItem(mainShortname)!!,
            accountId: Number(localStorage.getItem(accountId)!!),
        } : null
        setAuth(jwt)
    }, []);

    const authorize = (jwt: IJwt) => {
        localStorage.setItem(token, jwt.token)
        localStorage.setItem(mainShortname, jwt.mainShortname)
        localStorage.setItem(accountId, String(jwt.accountId))
        setAuth(jwt)
    }
    return (
        <ContextAuth.Provider value={{
            isAuth: !!getAuth,
            authorize,
            mainShortname: getAuth?.mainShortname,
            accountId: getAuth?.accountId
        }}>
            {getAuth !== undefined && children}
        </ContextAuth.Provider>
    );
};

export default AuthProvider;
