import React, {ChangeEvent, useContext, useEffect, useRef, useState} from 'react';
import WrapperImage from "../../../wrappers/WrapperImage";
import Toolbar from "../../../componts/Toolbar";
import {TextField, ThemeProvider} from "@mui/material";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {IGetContactType, IPostContact} from "../../../interface/IContact";
import ServiceContacts from "../../../serivices/ServiceContacts";
import {useParams} from "react-router-dom";
import Loader from "../../../componts/Loader";
import {textFieldTheme} from "../../../theme";
import {handleSubmitWithPreventDef, toFlow} from "../../../functions";
import ServiceCard from "../../../serivices/ServiceCard";
import StoreCard from "../../../store/StoreCard";
import {ContextNavigation} from "../../../provider/NavigationProvider";
import {ScreenSize} from "../../../const/screen";

interface IForm {
    value: string;
    title: string;
    description: string;
}

const ModalCreateContact = () => {
    const {shortname_param, type_param} = useParams()
    const shortname = shortname_param!!
    const type = type_param!!
    const {goTo, goBack, isPrevFlow} = useContext(ContextNavigation)
    const formRef = useRef<HTMLFormElement>(null)
    const [getContactType, setGetContactType] = useState<IGetContactType>()
    const {
        register, handleSubmit, trigger, reset,
        getValues, setValue,
        formState: {errors}
    } = useForm<IForm>()
    useEffect(() => {
        ServiceContacts.getContactType(type.toUpperCase())
            .then(res => setGetContactType(res.data))
    }, [type]);
    const onSubmit: SubmitHandler<IForm> = async data => {
        try {
            const postContact: IPostContact = {
                logoUrl: null,
                type: type.toUpperCase(),
                ...data
            }
            const getContact = (await ServiceCard.postContact(shortname, postContact)).data
            StoreCard.appendContact(getContact)
            goTo(toFlow('/edit/contacts'), {back: true})
        } catch (e) {

        }
    }

    return (
        getContactType != undefined ? <>
            <Toolbar
                text={getContactType?.writing}
                contextType={'FLOW'}
                btnRemove={(isPrevFlow() || window.innerWidth <= ScreenSize.md) ? {} : null}
                btnAction={{
                    text: 'Сохранить',
                    onClick: () => handleSubmitWithPreventDef(formRef)
                }}
            />
            <div>
                <div className={'px-[16px] pt-[12px] pb-[14px] grid grid-cols-5 max-sm:grid-cols-4'}>
                    <WrapperImage className={'rounded-[20px]'} url={getContactType.logoUrl}/>
                </div>
                <form className={'px-[16px]'} ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                    <ThemeProvider theme={textFieldTheme}>
                        <TextField
                            type="text"
                            label="Контакт"
                            placeholder={getContactType.urlBase}
                            error={!!errors.value?.message}
                            helperText={errors.value ? errors.value.message : null}
                            {...register('value', {
                                required: "Это обязательное поле",
                                pattern: {
                                    value: getContactType.regexp,
                                    message: 'Некорректное значение',
                                },
                            })}
                        />

                        <TextField
                            label={'Название'}
                            defaultValue={getContactType.writing}
                            error={!!errors.title?.message}
                            helperText={errors.title ? errors.title.message : null}
                            {...register('title', {required: "Это обязательное поле"})}
                        />
                        <TextField
                            label={'Описание'}
                            {...register('description')}
                        />
                    </ThemeProvider>
                </form>
            </div>
        </> : <Loader/>
    )
};

export default ModalCreateContact;