import React from 'react';
import Svg from "../../../media/Svg";
import {Link} from "react-router-dom";
import Toolbar from "../../../componts/Toolbar";
import {ScreenSize} from "../../../const/screen";
import LinkFrom from "../../../custome/LinkFrom";
import {toFlow} from "../../../functions";
import {ISetting} from "../../../interface/ISetting";
import SettingItem from "../../../items/settings/SettingItem";


const settings: ISetting[] = [
    {
        id: 0,
        title: 'Опыт работы',
        to: toFlow("/edit/resume/experiences"),
        icon: 'next',
        description: 'Где вы работаете?',
    },
    {
        id: 1,
        title: 'Образование',
        to: toFlow("/edit/resume/educations"),
        icon: 'next',
        description: 'Где вы проходили обучение?',
    },
    {
        id: 2,
        title: 'Навыки',
        to: toFlow("/edit/resume/skills"),
        icon: 'next',
        description: 'Какими навыками обладаете?',
    },
]

const ModalResume = () => {
    return (<div>
        <Toolbar
            text={'Резюме'}
            contextType={'FLOW'}
            btnRemove={window.innerWidth <= ScreenSize.md ? {} : null}
        />
        {settings.map(x => <SettingItem {...x}/>)}
    </div>)
};

export default ModalResume;