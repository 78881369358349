import React from 'react';
import CardDetailItem from "../items/CardDetailItem";
import {ICardDetail} from "../interface/ICard";

interface IList {
    list: ICardDetail[]
}

const ListCardDetails: React.FC<IList> = ({list}) => {
    return (
        <div className={'pb-[12px]'}>
            <div className={'flex flex-row flex-wrap gap-[12px]'}>
                {list.map(x => <CardDetailItem {...x}/>)}
            </div>
        </div>
    )
};

export default ListCardDetails;