import React, {useEffect, useState} from 'react';
import {useLocation, useNavigate} from "react-router-dom";
import {IJwt} from "../interface/IJwt";
import {token} from "./AuthProvider";
import {IReplace} from "../interface/IReplace";

interface IContext {
    goTo: (pathname: string, replace?: IReplace | null) => void,
    goBack: () => void,
    isPrevFlow: () => boolean,
    isPrevPage: () => boolean,
}

export const ContextNavigation = React.createContext<IContext>({
    goTo: (pathname: string) => console.log(`goTo:${pathname}`),
    goBack: () => console.log('goBack'),
    isPrevFlow: () => false,
    isPrevPage: () => false,
})

interface IProvider {
    children: React.ReactNode
}

const NavigationProvider: React.FC<IProvider> = ({children}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const goBack = () => {
        window.history.back()
    }
    const goTo = (pathname: string, replace?: IReplace | null) => {
        const prev_page = location.state?.from?.page
        const prev_flow = location.state?.from?.flow
        if (replace?.back == true && (prev_page == pathname || prev_flow == pathname)) goBack()
        navigate(pathname, {
            replace: !!replace,
            state: {
                from: {
                    page: replace ? prev_page : (pathname.includes('/flow/') ? prev_page : location.pathname),
                    flow: replace ? prev_flow : (location.pathname.includes('/flow/') ? location.pathname : null),
                }
            }
        })
    }

    const isPrevFlow = () => {
        return !!location.state?.from?.flow
    }
    const isPrevPage = () => {
        return !!location.state?.from?.page
    }
    return (
        <ContextNavigation.Provider value={{
            goTo,
            goBack,
            isPrevFlow,
            isPrevPage,
        }}>
            {children}
        </ContextNavigation.Provider>
    );
};

export default NavigationProvider;