import React, {useContext, useEffect} from 'react';
import {useParams} from "react-router-dom";
import ServiceCard from "../../../serivices/ServiceCard";
import Toolbar from "../../../componts/Toolbar";
import EducationItem from "../../../items/EducationItem";
import StoreCardEdit from "../../../store/StoreCard";
import SectionEmpty from "../../../sections/SectionEmpty";
import Loader from "../../../componts/Loader";
import {observer} from "mobx-react-lite";
import {ContextNavigation} from "../../../provider/NavigationProvider";
import {ScreenSize} from "../../../const/screen";

const ModalEducations = observer(() => {
    const {shortname_param} = useParams()
    const shortname=shortname_param!!
    const {isPrevFlow}=useContext(ContextNavigation)

    useEffect(() => {
        console.log(StoreCardEdit.getResume())
        StoreCardEdit.getResume() == undefined && ServiceCard.getResume(shortname!!)
            .then(res => StoreCardEdit.setResume(res.data))
    }, []);
    return (
        <>
            <Toolbar
                text={'Образование'}
                contextType={'FLOW'}
                btnRemove={(isPrevFlow() || window.innerWidth <= ScreenSize.md) ? {} : null}
                btnAction={{
                    text: 'Добавить',
                }}
            />
            {StoreCardEdit.getEducations() != undefined ?
                StoreCardEdit.isEducations() ?
                    StoreCardEdit.getEducations()!!.map(x => <EducationItem {...x}/>) :
                    <SectionEmpty
                        title={'Вы ещё не добавили образование'}
                        description={'Расскажите гостям вашей страницы, где вы учились'}
                    /> :
                <Loader/>}
        </>
    )

})
export default ModalEducations;