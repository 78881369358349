import React, {useEffect} from 'react';
import Title from "../componts/Title";
import EducationItem from "../items/EducationItem";
import ExperienceItem from "../items/ExperienceItem";
import SkillItem from "../items/SkillItem";
import WrapperComponent from "../wrappers/WrapperComponent";
import {IEducation, IExperience, IGetSkill, IResume} from "../interface/IResume";
import ServiceCard from "../serivices/ServiceCard";
import {useParams} from "react-router-dom";
import {toFlow} from "../functions";
import StoreCardEdit from "../store/StoreCard";
import SectionEmpty from "./SectionEmpty";
import Loader from "../componts/Loader";
import {observer} from "mobx-react-lite";
import {offline} from "../App";
import {contacts} from "./SectionContacts";
import {IGetContact} from "../interface/IContact";

const educations: IEducation[] = [
    {
        institution: "Санкт-Петербургский государственный экономический университет",
        stage: 'Бакалавр',
        specialization: 'Системный анализ и управление',
        graduationAt: 2024
    },
    {
        institution: "Акселератор TechnoProject",
        stage: 'Курсы повышения квалификации',
        specialization: 'Технологическое предпринимательство',
        graduationAt: 2023
    }
]
const experiences: IExperience[] = [
    {
        company: 'Livrizon',
        position: 'Мобильный разработчик',
        startAt: 'сен. 2021 г.',
        finishAt: 'фев. 2022 г',
        tasks: null
    },
    {
        company: 'OptionWay',
        position: 'Руководитель разработки',
        startAt: 'фев. 2022 г.',
        finishAt: 'авг. 2023 г',
        tasks: null
    },
    {
        company: 'Yetap',
        position: 'Руководитель проекта',
        startAt: 'авг. 2023 г.',
        finishAt: null,
        tasks: null
    }
]
const skills: IGetSkill[] = [
    {
        id: 0,
        skill: 'Java'
    },
    {
        id: 0,
        skill: 'Kotlin'
    },
    {
        id: 0,
        skill: 'Android разработка'
    },
    {
        id: 0,
        skill: 'JavaScript'
    },
    {
        id: 0,
        skill: 'React'
    },
    {
        id: 0,
        skill: 'HTML'
    },
    {
        id: 0,
        skill: 'CSS'
    },
    {
        id: 0,
        skill: 'TypeScript'
    },
    {
        id: 0,
        skill: 'Tailwind CSS'
    },
    {
        id: 0,
        skill: 'Redux'
    },
    {
        id: 0,
        skill: 'MobX'
    },
    {
        id: 0,
        skill: 'C++'
    },
    {
        id: 0,
        skill: 'Git'
    },
    {
        id: 0,
        skill: 'SQL'
    },
    {
        id: 0,
        skill: 'MySql'
    },
    {
        id: 0,
        skill: 'Figma'
    },
    {
        id: 0,
        skill: 'Spring Boot'
    },
]
const resume: IResume = {
    educations: educations,
    experiences: experiences,
    skills: skills

}
const SectionResume: React.FC = observer(() => {
    const {shortname_param, type_param} = useParams()
    const shortname = shortname_param!!

    const initResume = (resume: IResume) => {
        StoreCardEdit.setResume(resume)
    }
    useEffect(() => {
        if (!StoreCardEdit.isResumeInit()) {
            if (offline)
                initResume(resume)
            else
                ServiceCard.getResume(shortname)
                    .then(res => {
                        initResume(res.data)
                    })
        }
    }, [shortname_param]);
    return (
        StoreCardEdit.isResumeInit() ?
            (StoreCardEdit.isEducations() || StoreCardEdit.isExperiences() || StoreCardEdit.isSkills()) ?
                <>
                    {StoreCardEdit.isExperiences() && <div>
                        <Title text={'Опыт работы'}/>
                        {StoreCardEdit.getExperiences()!!.map(x => <ExperienceItem {...x}/>)}
                    </div>}
                    {StoreCardEdit.isEducations() && <div>
                        <Title text={'Образование'}/>
                        {StoreCardEdit.getEducations()!!.map(x => <EducationItem {...x}/>)}
                    </div>}
                    {StoreCardEdit.isSkills() && <div>
                        <Title text={'Навыки'}/>
                        <WrapperComponent className={'flex flex-row flex-wrap gap-[8px] px-[16px]'}>
                            {StoreCardEdit.getSkills()!!.map(x => <SkillItem remove={false} {...x}/>)}
                        </WrapperComponent>
                    </div>}
                </> :
                <SectionEmpty
                    title={'Вы ещё не заполнили резюме'}
                    description={'Расскажите гостям вашей страницы о своем опыте, образовании и навыках'}
                    btnAction={{
                        text: 'Заполнить резюме',
                        to: {
                            value: toFlow('/edit/resume')
                        }
                    }}
                /> :
            <Loader/>
    )

})

export default SectionResume;