import React, {useContext} from 'react';
import Svg from "../media/Svg";
import {Link} from "react-router-dom";
import {toFlow} from "../functions";
import {ContextNavigation} from "../provider/NavigationProvider";
import {ITo} from "../interface/IAction";

const FormatTypes = {
    sm_white_border_withouthover: {
        wrapper: 'border border-contour bg-white w-fit h-[38px]',
        text: 'text-black text-[15px] mx-[17px] my-auto',
        icon: 'm-auto'
    },
    sm_white_border: {
        wrapper: 'hover:bg-hover_button_white border border-contour bg-white w-fit h-[38px]',
        text: 'text-black text-[15px] mx-[17px] my-auto',
        icon: 'm-auto'
    },
    sm_white: {
        wrapper: 'hover:bg-hover_button_white bg-white w-fit h-[38px]',
        text: 'text-black text-[15px] mx-[17px] my-auto',
        icon: 'm-auto'
    },
    sm_main: {
        wrapper: 'hover:bg-hover_button_main bg-main w-fit h-[38px]',
        text: 'text-white text-[15px] mx-[17px] my-auto',
        icon: 'm-auto'
    },
    sm_transparent: {
        wrapper: 'hover:bg-hover_button_white w-fit h-[38px]',
        text: 'text-black text-[15px] mx-[17px] my-auto',
        icon: 'm-auto'
    },
    sm_dark: {
        wrapper: 'bg-black bg-opacity-[75%] hover:bg-hover_button_dark w-fit h-[38px]',
        text: 'text-white text-[15px] mx-[17px] my-auto',
        icon: 'm-auto'
    },
    sm_transparent_border: {
        wrapper: 'hover:bg-background_transparent border border-contour_transparent w-fit h-[38px]',
        text: 'text-white text-[15px] mx-[17px] my-auto',
        icon: 'm-auto'
    },
    sm_white_border_full: {
        wrapper: 'hover:bg-hover_button_white border border-contour bg-white w-full h-[44px]',
        text: 'text-[15px] font-bold m-auto my-auto',
        icon: 'm-auto'
    },
    lg_main: {
        wrapper: 'hover:bg-hover_button_main bg-main w-fit h-[52px]',
        text: 'text-white text-[17px] mx-[32px] my-auto text-white font-bold',
        icon: 'm-auto'
    },
    lg_main_full: {
        wrapper: 'hover:bg-hover_button_main bg-main w-full h-[52px]',
        text: 'text-white text-[17px] mx-[32px] my-auto text-white font-bold',
        icon: 'm-auto'
    },
} as const;

interface IComponent {
    type?: "submit" | "reset" | "button" | undefined,
    className?: string,
    format_type?: keyof typeof FormatTypes,
    content_type?: 'text' | 'icon',
    content: string,
    onClick?: () => void,
    to?: ITo,
}


const Button: React.FC<IComponent>
    = ({
           className, type = 'button',
           format_type = 'sm_white_border',
           content_type = 'text', content, onClick, to
       }) => {
    const {goTo} = useContext(ContextNavigation)
    const handleClick = () => {
        to && goTo(to.value, to.replace)
        onClick && onClick()
    }

    return (
        <button
            type={type}
            className={`${className && className} ${FormatTypes[format_type].wrapper} flex rounded-full ${content_type=='icon' && 'aspect-square'}`}
            onClick={handleClick}>
            {content_type == 'text' ?
                <span className={`${FormatTypes[format_type].text} font-bold w-full`}>{content}</span> :
                <Svg className={`${FormatTypes[format_type].icon}`} name={content}/>}
        </button>
    )
};

export default Button;