import React from 'react';
import Button from "../componts/Button";
import {IGetSkill} from "../interface/IResume";
import {useParams} from "react-router-dom";
import ServiceCard from "../serivices/ServiceCard";
import StoreCard from "../store/StoreCard";

interface IItem extends IGetSkill {
    remove: boolean
}

const SkillItem: React.FC<IItem> = ({id, skill, remove}) => {
    const {shortname_param} = useParams()
    const shortname = shortname_param!!

    const handleClick = () => {
        if (remove) {
            ServiceCard.deleteSkill(shortname, id)
            StoreCard.removeSkill(id)
        }
    }
    return (
        <Button format_type={remove?'sm_white_border':'sm_white_border_withouthover'} content={skill} onClick={handleClick}/>
    )
}

export default SkillItem;