import React, {ChangeEvent, useContext, useEffect, useRef, useState} from 'react';
import WrapperImage from "../../../wrappers/WrapperImage";
import Toolbar from "../../../componts/Toolbar";
import {TextField, ThemeProvider} from "@mui/material";
import {Controller, SubmitHandler, useForm} from "react-hook-form";
import {IGetContact, IGetContactType, IPutContact} from "../../../interface/IContact";
import ServiceContacts from "../../../serivices/ServiceContacts";
import {useParams} from "react-router-dom";
import Loader from "../../../componts/Loader";
import {textFieldTheme} from "../../../theme";
import {handleSubmitWithPreventDef, isNumeric, toFlow} from "../../../functions";
import ServiceCard from "../../../serivices/ServiceCard";
import StoreCard from "../../../store/StoreCard";
import {ContextNavigation} from "../../../provider/NavigationProvider";
import {ScreenSize} from "../../../const/screen";
import StoreCardEdit from "../../../store/StoreCard";

interface IForm {
    value: string;
    title: string;
    description: string;
}

const ModalEditContact = () => {
    const {shortname_param, id_param} = useParams()
    const shortname = shortname_param!!
    const id = isNumeric(id_param!!) ? Number(id_param) : 0
    const {goTo, isPrevFlow} = useContext(ContextNavigation)
    const formRef = useRef<HTMLFormElement>(null)
    const [getContact, setContact] = useState<IGetContact>()
    const [getContactType, setContactType] = useState<IGetContactType>()
    const {
        register, handleSubmit, trigger, reset,
        getValues, setValue,
        formState: {errors}
    } = useForm<IForm>()
    const init = () => {
        setContact(StoreCard.getContactById(id))
        setContactType({
            id: 1,
            logoUrl: 'https://s3.timeweb.com/2cc1de15-bc1f377d-9e5a-448f-8a1d-f117b93916d2/img_whatsapp.svg',
            type: 'PHONE',
            writing: 'Телефон',
            urlBase: 'tel:',
            regexp: /^[^\s@]+@[^\s@]+\.[^\s@]+$/,
        })
    }
    useEffect(() => {
        if (id) {
            if (StoreCardEdit.getContacts() == undefined) ServiceCard.getContacts(shortname)
                .then(res => {
                    StoreCardEdit.setContacts(res.data)
                    init()
                    if (StoreCard.getContactById(id)) init()
                })
            else if (StoreCard.getContactById(id)) init()
        }

    }, [shortname_param]);
    const onSubmit: SubmitHandler<IForm> = async data => {
        try {
            const putContact: IPutContact = {
                logoUrl: null,
                ...data
            }
            const getContact = (await ServiceCard.putContact(shortname, putContact)).data
            StoreCard.replaceContact(getContact)
            goTo(toFlow('/edit/contacts'), {back: true})
        } catch (e) {

        }
    }

    return (
        (getContactType != undefined && getContact) ? <>
            <Toolbar
                text={getContactType?.writing}
                contextType={'FLOW'}
                btnRemove={(isPrevFlow() || window.innerWidth <= ScreenSize.md) ? {} : null}
                isBtnTrash={true}
                btnAction={{
                    text: 'Сохранить',
                    onClick: () => handleSubmitWithPreventDef(formRef)
                }}
            />
            <div>
                <div className={'px-[16px] pt-[12px] pb-[14px] grid grid-cols-5 max-sm:grid-cols-4'}>
                    <WrapperImage
                        className={'rounded-[20px]'}
                        url={getContact.logoUrl}/>
                </div>
                <form className={'px-[16px]'} ref={formRef} onSubmit={handleSubmit(onSubmit)}>
                    <ThemeProvider theme={textFieldTheme}>
                        <TextField
                            type="text"
                            label="Контакт"
                            defaultValue={getContact.value}
                            placeholder={getContactType.urlBase}
                            error={!!errors.value?.message}
                            helperText={errors.value ? errors.value.message : null}
                            {...register('value', {
                                required: "Это обязательное поле",
                                pattern: {
                                    value: getContactType.regexp,
                                    message: 'Некорректное значение',
                                },
                            })}
                        />
                        <TextField
                            label={'Название'}
                            defaultValue={getContact.title}
                            error={!!errors.title?.message}
                            helperText={errors.title ? errors.title.message : null}
                            {...register('title', {required: "Это обязательное поле"})}
                        />
                        <TextField
                            label={'Описание'}
                            defaultValue={getContact.description}
                            {...register('description')}
                        />
                    </ThemeProvider>
                </form>
            </div>
        </> : <Loader/>
    )
}

export default ModalEditContact;