import React from 'react';
import WrapperImage from "../../wrappers/WrapperImage";
import WrapperComponent from "../../wrappers/WrapperComponent";
import {Link} from "react-router-dom";
import {IGetContactType} from "../../interface/IContact";
import LinkFrom from "../../custome/LinkFrom";
import {toFlow} from "../../functions";


const ContactTypeItem: React.FC<IGetContactType> = ({logoUrl, type, writing}) => {
    return (
        <LinkFrom to={toFlow(`/edit/contacts/types/${type.toLowerCase()}`)} replace={true} className={'flex pt-[4px] pb-[12px] w-full h-full flex-col items-center'}>
            <WrapperImage className={'rounded-[20px]'} url={logoUrl} pb={'pb-[100%]'}/>
            <p className={'mt-[10px]'}>{writing}</p>
        </LinkFrom>
    );
};

export default ContactTypeItem;