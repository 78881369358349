import React, {useEffect} from 'react';
import MediaItem from "../items/MediaItem";
import {useParams} from "react-router-dom";
import ServiceCard from "../serivices/ServiceCard";
import {IGetMedia} from "../interface/IMedia";
import {toFlow} from "../functions";
import StoreCardEdit from "../store/StoreCard";
import SectionEmpty from "./SectionEmpty";
import Loader from "../componts/Loader";
import {observer} from "mobx-react-lite";
import {offline} from "../App";
import {contacts} from "./SectionContacts";
import {IResume} from "../interface/IResume";
import {IGetFile} from "../interface/IFile";

const medias: IGetMedia[] = [
    {
        url: 'img_presentation.png'
    },
    {
        url: 'img_twitter.png'
    },
    {
        url: 'img_excel.png'
    },
]
const SectionMedias = observer(() => {
    const {shortname_param, type_param} = useParams()
    const shortname = shortname_param!!
    const initMedias = (medias: IGetFile[]) => {
        StoreCardEdit.setMedias(medias)
    }
    useEffect(() => {
        if (!StoreCardEdit.isMedias()) {
            //if (offline)
            //    initMedias(medias)
            //else
            //    ServiceCard.getMedias(shortname)
            //        .then(res => {
            //            initMedias(res.data)
            //        })
        }
    }, [shortname_param]);
    return (
        StoreCardEdit.getMedias() != undefined ?
            StoreCardEdit.isMedias() ? <div className={'grid grid-cols-3 w-full gap-[2px]'}>
                    {StoreCardEdit.getMedias()!!.map(x => <MediaItem key={x.id} {...x}/>)}
                </div> :
                <SectionEmpty
                    title={'Вы ещё не добавили медиа'}
                    description={'Наполните профиль необходимым контентом для гостей вашей страницы'}
                    btnAction={{
                        text: 'Добавить медиа',
                        to: {
                            value: toFlow('/edit/medias')
                        }
                    }}
                /> :
            <Loader/>
    )
})

export default SectionMedias;