import React, {useContext} from 'react';
import Toolbar from "../../../componts/Toolbar";
import {ContextNavigation} from "../../../provider/NavigationProvider";
import {ScreenSize} from "../../../const/screen";

const ModalExperience = () => {
    const {isPrevFlow}=useContext(ContextNavigation)
    return (
        <>
            <Toolbar
                text={'Место работы'}
                contextType={'FLOW'}
                btnRemove={(isPrevFlow() || window.innerWidth <= ScreenSize.md) ? {} : null}
            />
        </>
    );
};

export default ModalExperience;