import React from 'react';
import ModalSignIn from "../modal/authorization/ModalSignIn";
import WrapperFlow from "../wrappers/WrapperFlow";

const PageSignIn = () => {
    return (
        <WrapperFlow>
            <ModalSignIn/>
        </WrapperFlow>
    );
};

export default PageSignIn;