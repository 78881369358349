import React from 'react';
import {useLocation, useNavigate} from "react-router-dom";

interface IContext {
    closeModal: () => void
}

export const ContextModal = React.createContext<IContext>({
    closeModal: () => console.log('closeModal')
})
interface IProvider {
    children: React.ReactNode
}

const ModalProvider: React.FC<IProvider> = ({children}) => {
    const navigate = useNavigate()
    const location = useLocation()
    const closeModal = () => {
        navigate(location.pathname.split('/flow')[0])
    }
    return (
        <ContextModal.Provider value={{
            closeModal
        }}>
            {children}
        </ContextModal.Provider>
    );
};

export default ModalProvider;