import React, {useContext, useEffect} from 'react';
import Button from "./Button";
import ContainerDull from "../container/ContainerDull";
import {IToolbar} from "../interface/IToolbar";
import {ContextNavigation} from "../provider/NavigationProvider";
import {toFlow} from "../functions";
import {ContextModal} from "../provider/ModalProvider";
import {useLocation} from "react-router-dom";


const Toolbar: React.FC<IToolbar> = ({
                                         invisible = false,
                                         text,
                                         theme = 'LIGHT',
                                         btnRemove = {},
                                         contextType = 'PAGE',
                                         isBtnTrash = false,
                                         btnAction
                                     }) => {
    const location = useLocation()
    const {goBack, goTo, isPrevPage, isPrevFlow} = useContext(ContextNavigation)
    const {closeModal} = useContext(ContextModal)

    const removeType = btnRemove ?
        (btnRemove.type ? btnRemove.type : (contextType == 'PAGE' || !location.pathname.includes('/flow/')) ? (isPrevPage() ? 'BACK' : null) :
            contextType == 'FLOW' ? (isPrevFlow() ? 'BACK' : 'CLOSE') : null)
        : null
    const removeContent = removeType ? removeType.toLowerCase() + '-' + (theme == 'LIGHT' ? 'dark' : 'white') : null
    const removeClick = removeType == 'BACK' ? goBack : closeModal
    return (
        <ContainerDull invisible={invisible} className={`${!invisible && 'border-b'} z-toolbar px-[16px] top-0`}>
            <div className={'flex flex-row h-full flex-1 items-center'}>
                {removeContent && <div className={'flex w-[56px]'}>
                    <Button format_type={theme == 'LIGHT' ? 'sm_transparent' : 'sm_dark'} className={'ml-[-8px]'}
                            content_type={'icon'}
                            content={removeContent}
                            onClick={removeClick}/>
                </div>}
                {text && <span className={'w-full font-bold text-[20px]'}>{text}</span>}
            </div>
            <div className={'flex flex-row h-fit space-x-[8px]'}>
                {isBtnTrash && <Button content_type={'icon'} content={'trash'}/>}
                {btnAction && <Button content={btnAction.text} {...btnAction}/>}
            </div>
        </ContainerDull>
    );
};

export default Toolbar;