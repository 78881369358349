import React from 'react';
import Toolbar from "../../componts/Toolbar";
import SettingItem from "../../items/settings/SettingItem";
import {Outlet} from "react-router-dom";
import {ISetting} from "../../interface/ISetting";
import {toFlow} from "../../functions";

export const settings: ISetting[] = [
    {
        id: 0,
        title: 'Главное',
        to: toFlow("/edit"),
    },
    {
        id: 1,
        title: 'Контакты',
        to: toFlow("/edit/contacts"),
    },
    {
        id: 2,
        title: 'Резюме',
        to: toFlow("/edit/resume"),
    },
    {
        id: 3,
        title: 'Медиа',
        to: toFlow("/edit/medias"),
    },
    {
        id: 4,
        title: 'Файлы',
        to: toFlow("/edit/files"),
    },
]


const ModalSettings: React.FC = () => {
    return (
        <div
            className={'flex flex-row h-[650px] max-sm:h-full bg-white rounded-[12px] max-sm:rounded-[0px] overflow-hidden'}>
            <div className={'w-[250px] h-full max-md:hidden border-r'}>
                <Toolbar
                    text={'Настройки'}
                    contextType={'FLOW'}
                    btnRemove={{
                        type: 'close',
                    }}
                />
                <div className={'flex flex-col'}>
                    {settings.map(x =>
                        <SettingItem to={x.to} key={x.id} active={false} id={x.id} title={x.title}/>)}
                </div>
            </div>
            <div className={'flex flex-col h-full w-[600px] max-sm:w-full overflow-auto'}>
                <Outlet/>
            </div>
        </div>
    )
}

export default ModalSettings;