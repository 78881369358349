import React from 'react';
import WrapperComponent from "../wrappers/WrapperComponent";
import {IGetFile} from "../interface/IFile";
import {downloadFile} from "../functions";
import {getClodUrl} from "../const/url";

interface IItem extends IGetFile {

}

const FileItem: React.FC<IItem> = ({name, type, typeColor, size, url}) => {
    return (
        <div className={'flex flex-row pt-[4px] pb-[12px] items-center w-full cursor-pointer'}
             onClick={() => downloadFile(getClodUrl(url))}>
            <div className={'pr-[16px]'}/>
            <div className={'flex w-1/5 max-sm:w-1/4 rounded-[12px] aspect-square bg-empty'} style={{
                background: typeColor
            }}>
                <span className={'m-auto font-bold text-[20px] text-white'}>{type}</span>
            </div>
            <div className={'flex-1 ml-[12px]'}>
                <p className={'text-[15px]'}>{name}</p>
                <p className={'text-[13px] text-secondary'}>{size}</p>
            </div>
            <div className={'pl-[16px]'}/>
        </div>
    );
};

export default FileItem;